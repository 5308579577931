import "./quiz.css"
import { useState, useEffect } from "react";
import { backend } from "../../App";
import Test from "./test/test";
import Calc from "./calculator/calc";
import { useLocation, useNavigate } from "react-router-dom";
import AddQuestion from "../Teacher tools/quizMaker/addQuestion";
import Loading from "../../components/Loading/loading";


export default function Quiz(props) {

    if(!props.token) navigate('/');

    const [test, setTest] = useState();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState(JSON.parse(localStorage.getItem(`Answers`)) ? JSON.parse(localStorage.getItem(`Answers`)) : []);
    const [finalAnswers, setFinalAnswers] = useState({answers: [], startTime: "2024-07-19T12:00:00Z"})
    const [explanation, setExplanation] = useState('');
    const [timer, setTimer] = useState();
    const [startTime, setStartTime] = useState(Date.now());
    const [option, setOption] = useState(-1);
    const [activeIndex, setActiveIndex] = useState(0);
    const [hint, setHint] = useState(false);
    const [openCalc, setOpenCalc] = useState(false);
    const [revMark, setRevMark] = useState(null);
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [popOpen, setPopOpen] = useState(true);
    const [popOpenAdd, setPopOpenAdd] = useState(false);
    const [crossout, setCrossout] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState('');
    const [checkUpdate, setCheckUpdate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setNavMenuOpen(!navMenuOpen);
    };

    useEffect(() => {
        if(location.state === null){
            navigate('/')
        }
        const storedAnswers = JSON.parse(localStorage.getItem('Answers'));
        if (storedAnswers) {
          setAnswers(storedAnswers);
        }
      }, []);
    
      const changeQuestion = async (way) => {
        let updatedAnswers;
        if(answers['math']){
            updatedAnswers = [...answers['math']];
        }else if(answers['verbal']) {
            updatedAnswers = [...answers['verbal']];
        }else
        {
            updatedAnswers = [...answers];
        }
        const questionId = questions[activeIndex].question_id;
    
        let ind = updatedAnswers.findIndex(question => question.question_index === questionId);
        if (ind !== -1) {
          updatedAnswers[ind].answer = option;
          updatedAnswers[ind].isMarked = revMark;
        } else {
          updatedAnswers.push({ question_index: questionId, answer: option, isMarked: revMark });
        }
    
        localStorage.setItem('Answers', JSON.stringify(updatedAnswers));
        setAnswers(updatedAnswers);
    
        setRevMark(false);
    
        if (questions && questions.length !== 0) {
          if (way === 1 && activeIndex + 1 < questions.length) {
            setActiveIndex(activeIndex + 1);
          }
          if (way === -1 && activeIndex - 1 >= 0) {
            setActiveIndex(activeIndex - 1);
          }
        }
      };
    

    const getTime = () => {
        const time = startTime + test?.duration_minutes * 60000 - Date.now();
        setTimer({minutes: Math.floor((time / 1000 / 60)), second: Math.floor((time / 1000) % 60)});
        if(time === 0) {
            handleSubmit();
        }
    }

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, [test]);
    

    useEffect(() => {
        if(questions.length > 0 && localStorage.getItem('Answers'))
        {
            setCrossout([])
            const storedAnswers = JSON.parse(localStorage.getItem('Answers'));
            const questionId = questions[activeIndex].question_id;
            let ind;
            if(storedAnswers['math']){
                ind = storedAnswers['math'].findIndex(question => question.question_index === questionId);
            }else if(storedAnswers['verbal']) {
                ind = storedAnswers['verbal'].findIndex(question => question.question_index === questionId);
            }else
            {
                ind = storedAnswers.findIndex(question => question.question_index === questionId);
            }
            if (ind !== -1) {
                const currentAnswer = storedAnswers[ind];
                setOption(currentAnswer.answer !== undefined ? currentAnswer.answer : -1);
                setRevMark(currentAnswer.isMarked !== undefined ? currentAnswer.isMarked : false);
            } else {
                setRevMark(false);
            }
        }
    }, [activeIndex, questions]);


    useEffect(() => {
        if(questions.length > 0)
        {
            const storedAnswers = JSON.parse(localStorage.getItem('Answers')) || [];
            const questionId = questions[activeIndex].question_id;
            let ind;
            if(storedAnswers['math']){
                ind = storedAnswers['math'].findIndex(question => question.question_index === questionId);
            }else if(storedAnswers['verbal']) {
                ind = storedAnswers['verbal'].findIndex(question => question.question_index === questionId);
            }else
            {
                ind = storedAnswers.findIndex(question => question.question_index === questionId);
            }
            if (ind !== -1) {
                const currentAnswer = storedAnswers[ind];
                setOption(currentAnswer.answer !== undefined ? currentAnswer.answer : -1);
                setRevMark(currentAnswer.isMarked !== undefined ? currentAnswer.isMarked : false);
            } else {
                setRevMark(false);
            }
        }
    }, []);

    useEffect(() => {
        (
            async() => {
                if(location.state){
                    await fetch(`${backend}/api/tests/${location.state.quiz_id}/suspend/continue`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                if(data.questions.length > 0) {
                                    let updatedAnswers = []
                                    data.questions.map((question) => {
                                        question.answerOptions.map((option) => {
                                            if(option.selected === true){
                                                updatedAnswers.push({ question_index: question.question_id, answer: option.option_id, isMarked: null });
                                            }
                                        })
                                    })
                                    localStorage.setItem('Answers', JSON.stringify(updatedAnswers));
                                }
                            });
                        }
                    })
    
                    await fetch(`${backend}/api/tests/${location.state.quiz_id}/details`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                setTest(data);
                                setQuestions(data.questions)
                                setLoading(false)
                            });
                        }else {
                            console.log(response.status);
                        }
                    })
                }
            }
        )()
    },[props.token])

    useEffect(() => {
        (
            async() => {
                if(checkUpdate === true) {
                    await fetch(`${backend}/api/tests/${location.state.quiz_id}/details`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                setTest(data);
                                setQuestions(data.questions)
                            });
                        }else {
                            console.log(response.status);
                        }
                    })
                    setCheckUpdate(false);
                }
            }
        )()
    }, [checkUpdate])


    const handleSubmit = async() => {
        if(sessionStorage.getItem("role") === 'student') {
            changeQuestion(0);
            setFinalAnswers({answers: [], startTime: "2024-07-21T23:51:00Z"})
            const storedAnswers = JSON.parse(localStorage.getItem('Answers'));
            storedAnswers.map((ans) => {
                finalAnswers.answers.push({question_id: ans.question_index, answer: ans.answer})
            })
            await fetch(`${backend}/api/tests/${location.state.quiz_id}/submit`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                // credentials: 'include',
                body: JSON.stringify({
                    answers: finalAnswers.answers,
                    startTime: finalAnswers.startTime
                })
            }).then((response) => { 
                if(response && response.status === 200){
                    localStorage.removeItem('Answers')
                    navigate(`/${test.subject}`)
                }
            })
        }
        else {
            navigate(`/${test.subject}`)
            localStorage.removeItem('Answers')
        }
    }

    const handleSuspend = async() => {
        changeQuestion(0);
        setFinalAnswers({answers: [], startTime: "2024-07-21T23:51:00Z"})
        const storedAnswers = JSON.parse(localStorage.getItem('Answers'));
        storedAnswers.map((ans) => {
            finalAnswers.answers.push({question_id: ans.question_index, answer: ans.answer})
        })

        await fetch(`${backend}/api/tests/${location.state.quiz_id}/suspend`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
            // credentials: 'include',
            body: JSON.stringify({
                answers: finalAnswers.answers,
                startTime: finalAnswers.startTime
            })
        }).then((response) => { 
            if(response && response.status === 200){
                localStorage.removeItem('Answers');
                navigate(`/${test.subject}`)
            }
        })
    }

    const deleteQuestion = async(question_id) => {
        const response = await fetch(`${backend}/api/questions/${question_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            setCheckUpdate(true);
        }
    }

    const handleAddQuestion = async(e) => {
        e.preventDefault();
        setMode('add')
        setPopOpenAdd(true);
    }

    const handleUpdateQuestion = async(e) => {
        e.preventDefault();
        setMode('update')
        setPopOpenAdd(true);
    }

    if (loading) {
        return <Loading />;
    }

    const startScreen = (
        <div className="quiz-startScreen">
            <div>{test && test.name}</div>
            <div>{questions && questions.length} questions</div>
            <div></div>
            <button onClick={() => setPopOpen(false)}>Start</button>
        </div>
    )


    return (
        <div className="quiz-page">
            {sessionStorage.getItem("role") === 'student' && popOpen && startScreen}
            {popOpenAdd && <AddQuestion setPopOpen={setPopOpenAdd} mode={mode} testId={test.test_id} setCheckUpdate={setCheckUpdate} question={questions[activeIndex]} />}
            <div className="quiz-task">
                <div className="quiz-task-question-mark">
                    { (sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") ?
                    <>
                        <button className="quiz-addQuestion" onClick={handleAddQuestion}>Add new question</button>
                        <button className="quiz-addQuestion" onClick={handleUpdateQuestion}>Update question</button>
                        <button className="quiz-deleteQuestion" onClick={() => deleteQuestion(questions[activeIndex].question_id)}>Delete question</button>
                    </>
                    :
                    <>
                        <div className="quiz-task-question-mark-index">1</div>
                        <img src={`assets/icons/test-mark${String(revMark) === 'true' ? "-g" : ''}.svg`} alt="" onClick={() => setRevMark(!revMark)}/>
                        <div className="quiz-task-question-mark-text" onClick={() => setRevMark(!revMark)}>Mark for reveiw</div>
                    </>
                    }
                </div>
                {questions && <Test question={questions[activeIndex]} activeIndex={activeIndex} token={props.token} setOption={setOption} setCheckUpdate={setCheckUpdate} option={option} crossout={crossout} setCrossout={setCrossout}/>}
                <div className="quiz-task-nav">
                <button className="quiz-task-nav-button-r nav-button-text" onClick={handleSubmit}>End</button>
                {(sessionStorage.getItem("role") !== "admin" && sessionStorage.getItem("role") !== "teacher") && <button className="quiz-task-nav-button-r nav-button-text" onClick={handleSuspend}>Suspend</button>}
                    <div className="question-nav-container">
                        <button className="quiz-task-nav-button-q nav-button-text" onClick={toggleMenu}>Question {activeIndex+1}/{questions && questions.length}</button>
                        <div className={`question-nav ${navMenuOpen ? 'show' : ''}`}>
                            <ul>
                                {questions?.map((question, index) => (
                                    <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                        <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {questions && activeIndex !== 0 && <button className="quiz-task-nav-button-l nav-button-text" onClick={() => changeQuestion(-1)}>Back</button>}
                    {activeIndex !== questions.length-1 && <button className="quiz-task-nav-button-l nav-button-text" onClick={() => changeQuestion(1)}>Next</button>}
                </div>
            </div>
            {(sessionStorage.getItem("role") !== "admin" && sessionStorage.getItem("role") !== "teacher") &&
            <div className="quiz-rightBoard">
                {timer && timer.minutes && timer.second ? <div>{timer.minutes}:{timer.second < 10 ? "0" : ''}{timer.second}</div> : <div>Loading</div>}
                <div className="popup" onClick={() => setHint(!hint)}><img src="assets/images/hint.svg" alt="" />Hint
                    <span class={hint ? "popuptext show" : "popuptext"}>{questions && questions[activeIndex] && questions[activeIndex].hint}</span>
                </div>
                <div onClick={() => setOpenCalc(!openCalc)}><img src="assets/images/calculator.svg" alt="" />Calculator</div>
            </div>
            }
            {openCalc && <Calc setShow={setOpenCalc}/>}
        </div>
    )
}