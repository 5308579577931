import "./courseMain.css"
import SubjectTabs from "../subjectTabs/subjectTabs"
import { useEffect, useState } from "react"
import LessonMaker from "../../pages/Teacher tools/LessonMaker/lessonMaker";
import { backend } from "../../App";

export default function CourseMain(props) {

    const [openAll, setOpenAll] = useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const [popOpen, setPopOpen] = useState(false);

    const handleLesson = async() => {
        setPopOpen(true);
    }

    const handleDelete = async(id) => {
        const response = await fetch(`${backend}/api/lessons/${id}`, {
            method: "DELETE",
            headers: {'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
        }
        props.setRefresh(true);
    }

    return(
        <>
            {popOpen && <LessonMaker token={props.token} setPopOpen={setPopOpen} subject={props.subject} setRefresh={props.setRefresh}/>}
            <div className="course-content-lessons-open-close">
                <div className="course-content-lessons-open-close-button" onClick={() => {
                    setOpenAll(true)
                    setCloseAll(false);
                    }}><img src="assets/icons/header-arrow.png" alt="" className="header-arrow"/>  Open all</div>
                <div className="course-content-lessons-open-close-button" onClick={() => {
                    setOpenAll(false);
                    setCloseAll(true)
                    }}><img src="assets/icons/header-arrow.png" alt="" className="header-arrow"/>  Close all</div>
            </div>
            <div className="course-content-lessons-instruction">Instructions: Clicking on the section name will show / hide the section</div>
            <div className="course-content-tabs">
                {props.subjectLessons && props.subjectLessons.map((lesson, index) => 
                    <div key={index}>
                        <SubjectTabs openAll={openAll} closeAll={closeAll} setCloseAll={setCloseAll} title={lesson.title} lessonId={lesson.lessonId} index={index+1} token={props.token} subject={props.subject} setRefresh={props.setRefresh}/>
                        {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="course-add" onClick={() => handleDelete(lesson.lessonId)}>Delete Module</div>}
                    </div>
                )}
            </div>
            {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="course-add" onClick={handleLesson}>Add Module</div>}
        </>
    )
}