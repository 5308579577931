import "./topicMaker.css";
import React, { useEffect, useState } from "react";
import { backend } from "../../../App";

export default function TopicMaker(props) {
    const [sectionTitle, setSectionTitle] = useState("");
    const modes = ['quiz', 'file', 'video'];
    const [success, setSuccess] = useState();

    const createTopic = async () => {
        await fetch(`${backend}/api/topics`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            body: JSON.stringify({
                lesson_id: props.lessonId,
                title: sectionTitle
            })
        }).then((response) => {
            if (response && response.status === 201) {
                response.json().then((data) => {
                    props.setPopOpen(false)
                    window.location.reload();
                });
            } else {
                setSuccess(false);
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await createTopic();
    };

    return (
        <div className="topicMaker-page">
            <div className="topicMaker-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="topicMaker-block">
                <div className="topicMaker-ramka">
                    <div className="topicMaker-title">Add Topic</div>
                    <form onSubmit={handleSubmit} className="topic-maker-form">
                        <div>
                            <label>Topic Title: </label>
                            <input type="text" value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} required />
                        </div>
                        <button type="submit">Create Topic</button>
                    </form>
                    {success === false ? <div className="topicMaker-fail">Failed to create topic</div> : <></>}
                </div>
            </div>
        </div>
    );
}
