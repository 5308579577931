import React, { useState, useEffect } from 'react';
import GradeItem from './gradesItem';
import './grades.css';
import { backend } from '../../App';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/loading';
import Datas from '../GradesCurve';

const Grade = (props) => {

  const [grades, setGrades] = useState([{
    title: '',
    grade: '',
    percentage: '',
    attempts: []
  }]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const fetchTestData = async () => {
      if(props.subject !== 'sat'){
        const testsResponse = await fetch(`${backend}/api/tests`, {
          method: "GET",
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });

        if (testsResponse.status === 200) {
          const testsData = await testsResponse.json();
          const gradesData = await Promise.all(
            testsData.map(async (test) => {
              if(test.subject === props.subject){
                const attemptsResponse = await fetch(`${backend}/api/users/${sessionStorage.getItem('user_id')}/attempts/${test.test_id}`, {
                  method: "GET",
                  headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                });
  
                if (attemptsResponse.status === 200) {
                  const attemptsData = await attemptsResponse.json();
                  return {
                    title: test.name,
                    grade: '',
                    percentage: '',
                    attempts: attemptsData
                  };
                } else {console.log(attemptsResponse.status)}
  
                return {
                  title: test.title,
                  grade: '',
                  percentage: '',
                  attempts: []
                };
              }
            })
          );
          setGrades(gradesData);
          setLoading(false);
        }
      } else if(props.subject === 'sat') {
          const attemptsResponse = await fetch(`${backend}/api/satAttempts/user/${sessionStorage.getItem('user_id')}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
          });
          if(attemptsResponse && attemptsResponse.status === 200){
            const attemptsData = await attemptsResponse.json();
            const answersData = [];
            for (const attempt of attemptsData) {
              const answersResponse = await fetch(`${backend}/api/satAttempts/${attempt.attempt_id}/user/${sessionStorage.getItem('user_id')}/answers`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
              });
              const answers = await answersResponse.json();
              answersData.push(answers.attempt);
            }
            setAnswers(answersData);
            setLoading(false);
          }
        }
    };

    fetchTestData();
  }, []);


  function findTheHighest(attempts) {
    let highest = 0;
    attempts.map((att) => {
      if(att.score > highest) highest = att.score;
    })
    return highest;
  }

  function handleTotal() {
    let totalScore = 0;
    let length = 0;
    grades.map((grade, index) => {
      if(grade && grade.attempts.length > 0){
        totalScore += findTheHighest(grade.attempts)/grade.attempts[0]?.question_count * 100;
        length = index + 1;
      }
    })
    return totalScore/length;
  }

  if (loading) {
    return <Loading />;
  }

  
  return (
    <div className="grade">
      <div className='grade-ramka'>
        {props.subject !== 'sat' ? 
          <>
            <div className='grade-headers'>
              <div>Grade item</div>
              <div>Grade</div>
              <div>Percent</div>
              <div>Attempt</div>
            </div>
            <div className='grade-ramka-background'>
              <p><img src="assets/icons/folder.svg" alt="" /> Math</p>
              <div className="grade-items">
                {grades && grades.map((item, index) => (
                  <GradeItem index={index} title={item && item.title} item={item && item.attempts} token={props.token} subject={props.subject}/>
                ))}
              </div>
              <div className='grade-result'>
                <div>Course total</div>
                <div>{grades && grades[0] && grades[0].attempts.length > 0 && Math.round(handleTotal() * 100)/100}%</div>
              </div>
            </div>
          </>
        :
          <div className="satGrade-wrapper">
            {answers.map((attempt) => (
              <div className="satGrade">
                <div className="satGrade-title">{attempt.sat_test.name}</div>
                  <div className='satGrade-score'>
                    <div>MY FIRST SCORE</div>
                    <div>{Datas[1][attempt.scores.verbal] + Datas[0][attempt.scores.math]}</div>
                    <div>Out of 1600</div>
                  </div>
                  <div className='satGrade-sectionScore'>
                    <div>Verbal</div>
                    <div>{Datas[1][attempt.scores.verbal]}</div>
                  </div>
                  <div className='satGrade-sectionScore'>
                    <div>Math</div>
                    <div>{Datas[0][attempt.scores.math]}</div>
                  </div>
                <button className="satGrade-button" onClick={() => navigate('/satReview', {state:{attempt_id: attempt.sat_attempt_id, section: "verbal"}})}>Practice specific Questions</button>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
};

export default Grade;
