import { useState, useRef, useEffect } from "react";
import "./postConstructor.css";
import { backend } from "../../../App";
import '@mdxeditor/editor/style.css'
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, ListsToggle, listsPlugin, Separator, headingsPlugin, imagePlugin, BlockTypeSelect, InsertImage} from '@mdxeditor/editor'

export default function PostConstructor(props) {
    const [title, setTitle] = useState(props.title ? props.title : '');
    const [description, setDescription] = useState(props.description ? props.description : '');
    const [backImg, setBackImg] = useState('');
    const [uploadFile, setUploadFile] = useState(null);
    const [dataIMG, setDataIMG] = useState(props.backImg ? props.backImg : '');
    const [incorrect, setIncorrect] = useState(false);
    const fileUploadRef = useRef();
    const token = sessionStorage.getItem("token");
    const formData = new FormData();

    const handleAvatarUpload = async () => {
        fileUploadRef.current?.click();
    };

    const uploadProfileImage = async () => {
        if (fileUploadRef.current && fileUploadRef.current.files) {
            setUploadFile(fileUploadRef.current.files[0]);
        }
    };

    useEffect(() => {
        if (uploadFile && fileUploadRef.current && fileUploadRef.current.files) {
            let cachedURL = URL.createObjectURL(uploadFile);
            setBackImg(cachedURL);
            setDataIMG(uploadFile);
        }
    }, [uploadFile]);

    async function imageUploadHandler(image) {
        const formD = new FormData()
        formD.append('topic_id', 2)
        formD.append('title', '')
        formD.append('file', image)
        const response = await fetch(`${backend}/api/contents/file`, {
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + token },
          body: formD
        })
        const json = (await response.json())
        return json.resource;
    }

    const handleUpdatePost = async() => {
        setIncorrect(false);
        if (title === '' || description.length === 0) {
            setIncorrect(true);
            return;
        }

        formData.append('title', title);
        formData.append('content', JSON.stringify(description)); // Convert to JSON string
        formData.append('image', dataIMG);

        try {
            const response = await fetch(`${backend}/api/dashboard/announcements/${props.id}`, {
                method: "PUT",
                headers: { 'Authorization': 'Bearer ' + token },
                body: formData
            });
            if (response && response.status === 200) {
                props.setRefreshPosts(true);
                props.setConstructorOpen(false);
            } else {
                console.log(response.status)
            }
        } catch (error) {
            console.error("Error submitting post:", error);
            // Handle error
        }
    }
    
    const handleSubmitPost = async () => {
        setIncorrect(false);
        if (title === '' || description.length === 0 || backImg === '') {
            setIncorrect(true);
            return;
        }

        formData.append('title', title);
        formData.append('content', JSON.stringify(description)); // Convert to JSON string
        if (dataIMG) {
            formData.append('image', dataIMG);
        }

        try {
            const response = await fetch(`${backend}/api/dashboard/announcements`, {
                method: "POST",
                headers: { 'Authorization': 'Bearer ' + token },
                body: formData
            });
            if (response && response.status === 201) {
                props.setRefreshPosts(true);
                props.setConstructorOpen(false);
            } else {
                // Handle other responses
            }
        } catch (error) {
            console.error("Error submitting post:", error);
            // Handle error
        }
    };


    return (
        <div className="postConstructor">
            {
                props.mode === 'create' ? 
                <>
                    <div className="postConstructor-close" onClick={() => props.setConstructorOpen(false)}></div>
                    <div className="postConstructor-menu">
                        <div className="postConstructor-inputs">
                            <div className="pc-title">Create Title for Post:</div>
                            <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} className="pc-title-bar" />
                            
                            <div className="pc-title">Upload background image:</div>
                            <div className="pc-upload-image">
                                <button className="pc-image-button" onClick={handleAvatarUpload}>Upload</button>
                                {backImg && <img alt="" src={backImg} className="pc-backImg-preview" />}
                                <input type="file" ref={fileUploadRef} onChange={uploadProfileImage} hidden />
                            </div>
                            <div className="pc-title">Create description for your Post</div>
                            <MDXEditor
                            contentEditableClassName="postConstructor-mdxEditor"
                            markdown={description}
                            onChange={setDescription}
                            plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                toolbarPlugin({
                                toolbarContents: () => (
                                    <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <BlockTypeSelect />
                                    <Separator />
                                    <InsertImage />
                                    <Separator />
                                    <ListsToggle />
                                    </>
                                )
                                })
                            ]}
                            />
                            <button className="pc-submit" onClick={handleSubmitPost}>Add post</button>
                        </div>
                    </div>
                </>
                :
                props.mode === 'update' && 
                <>
                    <div className="postConstructor-close" onClick={() => props.setConstructorOpen(false)}></div>
                    <div className="postConstructor-menu">
                        <div className="postConstructor-inputs">
                            <div className="pc-title">Post Title:</div>
                            <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} className="pc-title-bar" />
                            <div className="pc-title">Upload background image:</div>
                            <div className="pc-upload-image">
                                <button className="pc-image-button" onClick={handleAvatarUpload}>Upload</button>
                                {backImg && <img alt="" src={backImg} className="pc-backImg-preview" />}
                                <input type="file" ref={fileUploadRef} onChange={uploadProfileImage} hidden />
                            </div>
                            <div className="pc-title">Create description for your Post</div>
                            <MDXEditor
                            contentEditableClassName="postConstructor-mdxEditor"
                            markdown={description}
                            onChange={setDescription}
                            plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                toolbarPlugin({
                                toolbarContents: () => (
                                    <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <BlockTypeSelect />
                                    <Separator />
                                    <InsertImage />
                                    <Separator />
                                    <ListsToggle />
                                    </>
                                )
                                })
                            ]}
                            />
                            <button className="pc-submit" onClick={handleUpdatePost}>Update post</button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
