import Dashboard from "../../components/Dashboard/dashboard";
import "./demoSAT.css"
import { useEffect, useState } from "react";
import Subject_Header from "../../components/subject_header/subject_header"
import PracticeTest from "../../components/practice test/practiceTest";
import { backend } from "../../App";
import { useNavigate } from "react-router-dom";
import Grade from "../../components/Grades/grades";
import Loading from "../../components/Loading/loading";
import Datas from "../../components/GradesCurve";

export default function DemoSAT(props) {

    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const [choose, setChoose] = useState('Sat');
    const [showStat, setShowStat] = useState(false);
    const [practices, setPractices] = useState([]);
    const [firstScore, setFirstScore] = useState(0);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        (
            async() => {
                await fetch(`${backend}/api/satTests`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                }).then((response) => {
                    if (response && response.status === 200) {
                        response.json().then((data) => {
                            const extractedData = [];
                            data.map(test => {
                                let arr = {
                                    group_id: -1,
                                    open: 0,
                                    due: 0
                                }
                                if(test.sat_test_deadlines.find((time) => time.group_id === Number(sessionStorage.getItem('group_id')))){
                                    arr = test.sat_test_deadlines.find((time) => time.group_id === Number(sessionStorage.getItem('group_id')));
                                }
                                extractedData.push({
                                    sat_test_id: test.sat_test_id,
                                    name: test.name,
                                    opens: arr.open,
                                    due: arr.due
                                })
                            })
                            setPractices(extractedData);
                            setLoading(false);
                        });
                    }
                });
            }
        )()
    },[])

    useEffect(() => {
        (
            async() => {
                if(refresh === true){
                    await fetch(`${backend}/api/satTests`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                    }).then((response) => {
                        if (response && response.status === 200) {
                            response.json().then((data) => {
                                const extractedData = [];
                                data.map(test => {
                                    let arr = {
                                        group_id: -1,
                                        open: 0,
                                        due: 0
                                    }
                                    if(test.sat_test_deadlines.find((time) => time.group_id === Number(sessionStorage.getItem('group_id')))){
                                        arr = test.sat_test_deadlines.find((time) => time.group_id === Number(sessionStorage.getItem('group_id')));
                                    }
                                    extractedData.push({
                                        sat_test_id: test.sat_test_id,
                                        name: test.name,
                                        opens: arr.open,
                                        due: arr.due
                                    })
                                })
                                setPractices(extractedData);
                            });
                        }
                    });
                    setRefresh(false);
                }
            }
        )()
    },[refresh])

    useEffect(() => {
        (
            async() => {
                await fetch(`${backend}/api/satAttempts/user/${sessionStorage.getItem('user_id')}`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                }).then((response) => {
                    if (response && response.status === 200) {
                        response.json().then((data) => {
                            if(data.length > 0){
                                setFirstScore(data[0].scores)
                            }
                        });
                    }
                });
            }
        )()
    },[])

    if (loading) {
        return <Loading />;
    }

    return(
        <div className="demoSAT">
            <div className="demoSAT-dashboard">
                <Dashboard setChoose={setChoose} choose={choose} />
            </div>
            <div className="demoSAT-content-wrapper">
                <Subject_Header title="Demo SAT" path={choose} />
                <div className="demoSAT-content">
                {
                        choose === "Sat" ?
                        <>
                            {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <button className="demoSAT-add" onClick={() => navigate('/create-SAT-practice')}>Create SAT practice</button>}
                            <div className="demoSAT-content-statistics">
                            <p>See your SAT statistics to know what you need to improve on</p>
                            {showStat && 
                                <div className="demoSAT-content-statistics-graph">
                                    <div className="statistics-graph-title">SAT statistics</div>
                                    <div className="statistics-graph-items-wrapper">
                                        <div className="statistics-graph-item">
                                            <div>MY FIRST SCORE</div>
                                            <div>{firstScore !== 0 ? Datas[1][firstScore.verbal] + Datas[0][firstScore.math] : 0}</div>
                                            <div>Based on placement test</div>
                                        </div>
                                        <div className="statistics-graph-item">
                                            <div>MY APPROXIMATE SCORE</div>
                                            <div>0</div>
                                            <div>Based on the last two exams</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <button onClick={() => setShowStat(!showStat)}>{showStat ? "Hide Statistics" : "Show Statistics"}</button>
                            </div>
                            <div className="demoSAT-content-practices">
                                {practices[0] && practices.sort((a,b) => a.sat_test_id - b.sat_test_id).map((test) => (
                                <PracticeTest test={test} setRefresh={setRefresh}/>
                                ))}
                            </div>
                        </>
                        :
                        choose === "Grades" ?
                            <Grade token={props.token} subject="sat"/>
                            :
                            <></>
                }

                </div>
            </div>
        </div>
    )

}