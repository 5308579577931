import { useEffect, useState } from "react";
import "./addEvent.css";
import { backend } from "../../../App";
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, ListsToggle, listsPlugin, Separator, headingsPlugin, BlockTypeSelect, linkPlugin} from '@mdxeditor/editor'

export default function AddEvent(props) {
    const [eventDetails, setEventDetails] = useState({
        title: "",
        description: "",
        group_id: 1,
        startTime: "",
        endTime: ""
    });
    const [description, setDescription] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + props.token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                      setSelectedGroup(data[0].group_id); // Set default selected group
                    }
                  }
            }
        )()
      }, [backend]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventDetails({ ...eventDetails, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${backend}/api/calendars`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            body: JSON.stringify({
                title: eventDetails.title,
                description: description,
                group_id: selectedGroup,
                startTime: eventDetails.startTime,
                endTime: eventDetails.endTime
            })
        }).then((response) => {
            if (response && response.status === 201) {
                props.setPopOpen(false);
                window.location.reload();
            } else {
                console.log("Error: " + response.status);
            }
        });
    };

    return (
        <div className="add-event-container">
            <div className="add-event-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="add-event-block">
                <div className="add-event-ramka">
                    <h1>Create Event</h1>
                    <form onSubmit={handleSubmit} className="add-event-form">
                        <label>Choose group:</label>
                        <select 
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            className="form-group"
                            >
                            {groups[0] && groups.map(group => (
                            <option key={group.group_id} value={group.group_id}>
                                {group.name}
                            </option>
                            ))}
                        </select>
                        <div className="form-group">
                            <label>Title:</label>
                            <input
                                type="text"
                                name="title"
                                value={eventDetails.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Description:</label>
                            <MDXEditor
                            contentEditableClassName="calendar-mdxEditor"
                            markdown={description}
                            onChange={setDescription}
                            plugins={[listsPlugin(), linkPlugin(),
                                toolbarPlugin({
                                toolbarContents: () => (
                                    <>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <Separator />
                                    <ListsToggle />
                                    </>
                                )
                                })
                            ]}
                            />
                        </div>
                        <div className="form-group">
                            <label>Start Time:</label>
                            <input
                                type="datetime-local"
                                name="startTime"
                                value={eventDetails.startTime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>End Time:</label>
                            <input
                                type="datetime-local"
                                name="endTime"
                                value={eventDetails.endTime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button">Create Event</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
