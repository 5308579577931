import "./createStudent.css"
import { useState, useEffect } from "react";
import { backend } from "../../../App";
import InputMask from 'react-input-mask';


export default function CreateStudent(props) {
    
    const [email, setEmail] = useState("");
    const [name, setName] = useState('');
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("student");
    const [phone, setPhone] = useState('+7');
    const [emailEmpty, setEmailEmpty] = useState(true);
    const [passwordEmpty, setPasswordEmpty] = useState(true);
    const [accAdded, setAccAdded] = useState(false);
    const [userId, setUserId] = useState();
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const token = sessionStorage.getItem('token');
    const [error, setError] = useState('');

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                      setSelectedGroup(data[0].group_id); // Set default selected group
                    }
                  }
            }
        )()
      }, [backend]);
    
    const handleCreateStudent = () => {
        const userId = sessionStorage.getItem('user_id');
        if (!userId || !selectedGroup) {
          alert('User ID or Group is missing');
          return;
        }
    
        fetch(`${backend}/api/groups/${selectedGroup}/memberships`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: userId })
        })
        .then(response => response.json())
        .then(data => {
          // handle success, maybe redirect or show a message
        })
        .catch(error => console.error('Error creating student:', error));
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value)
    };

    const handleSelectChange = (event) => {
        setRole(event.target.value);
    };


    const handleKeywordKeyPress = (e) => {
        if( e.key === 'Enter' ) createStudent(e);
    };

    useEffect(() => {
        (
        async () => {
            setEmail(email.trim());
            setPassword(password.trim())
            setAccAdded(false)
        })();
    }, [email, password])

    const createStudent = async(e) => {
        e.preventDefault();
        setError('');
        if(email.length === 0 || password.length === 0 || phone.includes('_') || name.length === 0) {
            if(phone.includes('_')) setError('phone');
            if(password.length === 0) setError('password');
            if(email.length === 0) setError('email');
            if(name.length === 0) setError('name');
            return;
        }

        await fetch(`${backend}/api/auth/signup`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            // credentials: 'include',
            body: JSON.stringify({
                name: String(name),
                email: email,
                password: password,
                role: String(role),
                phone_number: String(phone)
            })
        }).then((response) => { 
            if (response && response.status === 201)
            {
                response.json().then((data) => {
                    setAccAdded(true);
                    setUserId(data.user_id)
                })
            }
            else {
                setAccAdded(false);
                setError(': acc already exists');
            }
        })
    }

    useEffect(() => {
        (
            async() => {
                if(userId)
                {
                    await fetch(`${backend}/api/groups/${selectedGroup}/memberships`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                        // credentials: 'include',
                        body: JSON.stringify({
                            user_id: userId
                        })
                    }).then((response) => { 
                        if (response && response.status === 201)
                        {
                            response.json().then((data) => {
                            })
                        }
                    })
                }
            }
        )()
    },[userId])

    return(
        <div className="createStudent-page">
            <div className="createStudent-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="createStudent-block">
                <div className="createStudent-ramka">
                    <div className="createStudent-content">
                        <div className="createStudent-title">Add Student</div>
                        <div className="createStudent-password">
                            <div className="createStudent-bar-text">Name</div>
                            <input type="text" value={name} className="createStudent-Bar" onChange={(e) => setName(e.target.value)} required/>
                        </div>
                        <div className="createStudent-email">
                            <div className={emailEmpty ? "createStudent-bar-text" : "createStudent-bar-text createStudent-empty"}>Email Address</div>
                            <input type="text" onKeyUp={handleKeywordKeyPress} className={emailEmpty ? "createStudent-Bar" : "createStudent-Bar createStudent-incorrectBar"} onChange={(e) => setEmail(e.target.value)} value={email}/>
                        </div>
                        <div className="createStudent-password">
                            <div className={passwordEmpty ? "createStudent-bar-text" : "createStudent-bar-text createStudent-empty"}>Password</div>
                            <input type="password" onKeyUp={handleKeywordKeyPress} className={passwordEmpty ? "createStudent-Bar" : "createStudent-Bar createStudent-incorrectBar"} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="createStudent-password">
                            <div className="createStudent-bar-text">Role</div>
                            <select 
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="createStudent-Bar"
                            >
                                <option value="student">
                                    Student
                                </option>
                               {sessionStorage.getItem('role') === 'admin' && <option value="teacher">
                                    Teacher
                                </option>}
                            </select>
                        </div>
                        <div className="createStudent-password">
                            <div className="createStudent-bar-text">Group</div>
                            <select 
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            className="createStudent-Bar"
                            >
                            {groups[0] && groups.map(group => (
                            <option key={group.group_id} value={group.group_id}>
                                {group.name}
                            </option>
                            ))}
                            </select>
                        </div>
                        <div className="createStudent-password">
                            <div className="createStudent-bar-text">Phone number</div>
                            <InputMask className="createStudent-Bar" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" id="phoneNumber"
                                  name="phoneNumber"
                                  value={phone}
                                  onChange={handlePhoneChange}
                                  required/>
                        </div>
                        <button className="createStudent-button" onClick={createStudent}>Create</button>
                        {accAdded && error === '' && <div className="createStudent-success">Student was added successfully</div>}
                        {error !== '' && <div className="createStudent-email-incorrect">Incorrect {error} </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}