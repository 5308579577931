import "./satTestReview.css"
import { useState, useEffect } from "react";
import { backend } from "../../../App";
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin } from '@mdxeditor/editor'

const alphabet = ["A", "B", "C", "D", "E","F","G","H","I","J","K","L","M","N","O","P"];

export default function TestReview(props) {
    const [answers, setAnswers] = useState([{
        selected: false,
        is_correct: false,
        option_text: ''
    }])
    const [editorKey, setEditorKey] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 1);
        }, 200);
 
        return () => clearInterval(interval);
    }, [count]);

    // Update the key whenever activeIndex changes
    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [props.question]);

    useEffect(() => {
        (
            async() => {
                let arrAns = [];
                props.question?.sat_answer_options?.map((answer) => {
                    fetch(`${backend}/api/satAnswerOptions/${answer.sat_answer_option_id}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                arrAns.push({
                                    selected: answer.selected,
                                    is_correct: answer.is_correct,
                                    option_text: data.text
                                })
                            });
                        }
                    })
                })
                setAnswers(arrAns);
            }
        )()
    },[props.activeIndex])

    return(
        <div>
            <div className="testReview-task-question">
                <div className="testReview-task-question-content">
                    <div>{props.question &&
                                <MDXEditor
                                    key={editorKey}
                                    contentEditableClassName="testReview-mdxEditor"
                                    markdown={props.question.question_text}
                                    plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
                                    ]}
                                />
                            }
                    </div>
                    <img src={props.question.image} alt="" />
                    {answers.map((option, index) => (
                        <>
                            <div key={index} className={option.selected ? "testReview-option active" : option.is_correct ? "testReview-option correct" : "testReview-option"}><div className={option.is_correct ? "testReview-letter-correct" : "testReview-letter"}>{option.is_correct ? <img src="assets/images/correct.svg" alt="" /> : alphabet[index]}</div><span>{option.option_text}</span></div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}