import "./subTab.css"
import Tab from "../tab/tab"
import SlideIn from "../../post-components/slideIn/slideIn"
import { useState, useEffect } from "react";
import { backend } from "../../../App";
import { useNavigate } from "react-router-dom";

export default function SubTab(props) {
    const [openTab, setOpenTab] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [content, setContent] = useState([
        {
            title: '',
            mode: '',
            resource: ''
        }
    ])

    useEffect(() => {
        (
            async() => {
                setOpenTab(props.openAll)
            }
        )()
    },[props.openAll])

    useEffect(() => {
        (
            async() => {
                if(props.topicId !== -1){
                    const contentResponse = await fetch(`${backend}/api/contents/topic/${props.topicId}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                    });
                    if (contentResponse && contentResponse.status === 200) {
                        const data = await contentResponse.json();
                        setContent(prevState => {
                            return data.map(content => ({
                                content_id: content.content_id,
                                title: content.title,
                                mode: content.mode,
                                resource: content.resource,
                                isDone: content.isDone
                            }));
                        });
                    }
                }
            }
        )()
    },[props.topicId])

    useEffect(() => {
        (
            async() => {
                if(refresh === true){
                    const contentResponse = await fetch(`${backend}/api/contents/topic/${props.topicId}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                    });
                    if (contentResponse && contentResponse.status === 200) {
                        const data = await contentResponse.json();
                        setContent(prevState => {
                            return data.map(content => ({
                                content_id: content.content_id,
                                title: content.title,
                                mode: content.mode,
                                resource: content.resource,
                                isDone: content.isDone
                            }));
                        });
                    }
                    setRefresh(false);
                }
            }
        )()
    },[refresh])

    const handleContent = async() => {
        navigate('/create-content', {state:{ topicId: props.topicId, subject: props.subject }})
    }

    const handleDelete = async(content_id, index) => {
        const response = await fetch(`${backend}/api/contents/${content_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            setRefresh(true);
        }
        if(content[index].mode === 'text'){
            const response = await fetch(`${backend}/api/tests/${content[index].resource}`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            });
            if (response && response.status === 200) {
                const data = await response.json();
            }
        }
    }


    return(
        <>
            <div className="subTab-wrapper">
                <div className="subTab" onClick={() => setOpenTab(!openTab)}>
                    <div className="subTab-trans">
                        <div className="subTab-arrow-wrapper"><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subTab-arrow-wrapper-image-down" : "subTab-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subTab-arrow-wrapper-image-down" : "subTab-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subTab-arrow-wrapper-image-down" : "subTab-arrow-wrapper-image-up"} /></div>
                        <div className="subTab-image-title">{props.title}</div>
                    </div>
                </div>
            </div>
            {
                openTab &&
                <SlideIn startAnimation={openTab} slideStyle="slide-in-tab">
                    {content[0].title !== '' && content.map((content, index) => 
                        <div key={index}>
                            <Tab number={`${props.index+1}.${index+1}`} isDone={content.isDone} content_id={content.content_id} title={content.title} mode={content.mode} resource={content.resource} token={props.token} setRefresh={setRefresh}/>
                            {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="subTab-minus" onClick={() => handleDelete(content.content_id, index)}>Delete content</div>}
                        </div>
                    )}
                    {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="subTab-add" onClick={handleContent}>Add content</div>}
                </SlideIn>
            }
        </>
    )
}