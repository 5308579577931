import "./addNotification.css";
import React, { useEffect, useState } from "react";
import { backend } from "../../../App";

export default function NotificationMaker(props) {

    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState();
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const token = sessionStorage.getItem('token');

    const createNotification = async () => {
        await fetch(`${backend}/api/notifications`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({
                message: message,
                group_id: selectedGroup
            })
        }).then((response) => {
            if (response && response.status === 201) {
                response.json().then((data) => {
                    props.setRefresh(true);
                    props.setPopOpen(false)
                });
            } else {
                console.log(response.status)
                setSuccess(false);
            }
        });
    };

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                      setSelectedGroup(data[0].group_id); // Set default selected group
                    }
                  }
            }
        )()
      }, [backend]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await createNotification();
    };

    return (
        <div className="notificationMaker-page">
            <div className="notificationMaker-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="notificationMaker-block">
                <div className="notificationMaker-ramka">
                    <div className="notificationMaker-title">Create Notification</div>
                    <form onSubmit={handleSubmit} className="notification-maker-form">
                        <div>
                            <label>Message: </label>
                            <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} required />
                        </div>
                        <div>
                            <label>Group: </label>
                            <select 
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            >
                            {groups[0] && groups.map(group => (
                            <option key={group.group_id} value={group.group_id}>
                                {group.name}
                            </option>
                            ))}
                            </select>
                        </div>
                        <button type="submit">Create</button>
                    </form>
                    {success === false ? <div className="notificationMaker-fail">Failed to create notification</div> : <></>}
                </div>
            </div>
        </div>
    );
}
