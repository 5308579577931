import "./lessonMaker.css";
import React, { useEffect, useState } from "react";
import { backend } from "../../../App";

export default function LessonMaker(props) {

    const [sectionTitle, setSectionTitle] = useState("");
    const [success, setSuccess] = useState();
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + props.token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                        if(sessionStorage.getItem('group_id')){
                            setSelectedGroup(sessionStorage.getItem('group_id'));
                        }
                        else {
                            setSelectedGroup(data[0].group_id); // Set default selected group
                        }
                    }
                  }
            }
        )()
      }, [backend]);

    const createLesson = async () => {
        await fetch(`${backend}/api/lessons`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            body: JSON.stringify({
                group_id: selectedGroup,
                subject: props.subject,
                section_title: sectionTitle
            })
        }).then((response) => {
            if (response && response.status === 201) {
                response.json().then((data) => {
                    props.setRefresh(true);
                    props.setPopOpen(false)
                });
            } else {
                setSuccess(false);
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await createLesson();
    };

    return (
        <div className="lessonMaker-page">
            <div className="lessonMaker-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="lessonMaker-block">
                <div className="lessonMaker-ramka">
                    <div className="lessonMaker-title">Add Module</div>
                    <form onSubmit={handleSubmit} className="lesson-maker-form">
                        <div>
                            <label>Group: </label>
                            <select 
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            className="lessonMaker-group"
                            >
                            {groups[0] && groups.map(group => (
                            <option key={group.group_id} value={group.group_id}>
                                {group.name}
                            </option>
                            ))}
                            </select>
                        </div>
                        <div>
                            <label>Section Title: </label>
                            <input type="text" value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} required />
                        </div>
                        <button type="submit">Create Lesson</button>
                    </form>
                    {success === false ? <div className="lessonMaker-fail">Failed to create lesson</div> : <></>}
                </div>
            </div>
        </div>
    );
}
