import React, { useEffect, useState } from "react";
import { backend } from "../../../App";
import "./groupAdd.css";

export default function CreateGroup(props) {
    const [groupName, setGroupName] = useState(props.groupTitle ? props.groupTitle : "");
    const [teachers, setTeachers] = useState([]);
    const [member, setMember] = useState(null);
    const [teacherId, setTeacherId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/user`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + props.token,
                    },
                });
                if (response && response.status === 200) {
                    const data = await response.json();
                    let arr = [];
                    data.map((role) => {
                        if(role.role === 'teacher') arr.push(role)
                    })
                    setTeachers(arr);
                    if (data.length > 0) {
                        if(props.groupTeacher){
                            setTeacherId(props.groupTeacher);
                        }
                        else {
                            setTeacherId(data[0].user_id);
                        }
                    }
                }
                const res = await fetch(`${backend}/api/groups/${props.groupId}/memberships`, {
                    method: "GET",
                    headers: { 'Authorization': 'Bearer ' + props.token }
                });
                if (res.ok) {
                    const data = await res.json();
                    let membership = data.find((user) => user.user_id === props.groupTeacher)
                    setMember(membership);
                }
            }
        )()
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(null);
        setError(null);

        try {
            if(props.mode && props.mode === 'change'){
                const response = await fetch(`${backend}/api/groups/${props.groupId}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + props.token,
                    },
                    body: JSON.stringify({
                        name: groupName,
                        teacher_id: teacherId,
                    }),
                });
                if (response.ok) {
                    response.json().then((data) => {
                        if(teacherId === props.groupTeacher) {
                            props.refresh(true);
                            props.setPopOpen(false);
                        } else {
                            member && fetch(`${backend}/api/groups/${props.groupId}/memberships/${member.membership_id}`, {
                                method: "DELETE",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": "Bearer " + props.token,
                                },
                            });
                            fetch(`${backend}/api/groups/${props.groupId}/memberships`, {
                                method: "POST",
                                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                                // credentials: 'include',
                                body: JSON.stringify({
                                    user_id: teacherId
                                })
                            }).then((response) => { 
                                if (response && response.status === 201)
                                {
                                    props.refresh(true);
                                    props.setPopOpen(false);
                                } else {
                                    console.log(response.status)
                                }
                            })
                        }
                    })
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || "Failed to update group.");
                }
            }
            else {
                const response = await fetch(`${backend}/api/groups`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + props.token,
                    },
                    body: JSON.stringify({
                        name: groupName,
                        teacher_id: teacherId,
                    }),
                });
    
                if (response.ok) {
                    response.json().then((data) => {
                        fetch(`${backend}/api/groups/${data.group_id}/memberships`, {
                            method: "POST",
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                            // credentials: 'include',
                            body: JSON.stringify({
                                user_id: teacherId
                            })
                        }).then((response) => { 
                            if (response && response.status === 201)
                            {
                                props.refresh(true);
                                props.setPopOpen(false);
                            } else {
                                console.log(response.status)
                            }
                        })
                    })
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || "Failed to create group.");
                }
            }
        } catch (error) {
            setError("An error occurred while creating the group.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-group">
            <div className="create-group-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="create-group-block">
                <div className="create-group-ramka">
                    {
                        props.mode && props.mode === 'change' ?
                        <>
                            <h1>Update Group</h1>
                            <form onSubmit={handleSubmit} className="create-group-form">
                                <div>
                                    <label>Group Name</label>
                                    <input
                                        type="text"
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <select 
                                    value={teacherId}
                                    onChange={(e) => setTeacherId(e.target.value)}
                                    className="createStudent-Bar"
                                    >
                                    {teachers[0] && teachers.map(teacher => (
                                    <option key={teacher.user_id} value={teacher.user_id}>
                                        {teacher.name}
                                    </option>
                                    ))}
                                </select>
                                </div>
                                <button type="submit" disabled={loading}>
                                    {loading ? "Updating..." : "Update Group"}
                                </button>
                                {success && <p className="success-message">{success}</p>}
                                {error && <p className="error-message">{error}</p>}
                            </form>
                        </>
                        :
                        <>
                            <h1>Create a New Group</h1>
                            <form onSubmit={handleSubmit} className="create-group-form">
                                <div>
                                    <label>Group Name</label>
                                    <input
                                        type="text"
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <select 
                                    value={teacherId}
                                    onChange={(e) => setTeacherId(e.target.value)}
                                    className="createStudent-Bar"
                                    >
                                    {teachers[0] && teachers.map(teacher => (
                                    <option key={teacher.user_id} value={teacher.user_id}>
                                        {teacher.name}
                                    </option>
                                    ))}
                                </select>
                                </div>
                                <button type="submit" disabled={loading}>
                                    {loading ? "Creating..." : "Create Group"}
                                </button>
                                {success && <p className="success-message">{success}</p>}
                                {error && <p className="error-message">{error}</p>}
                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
