import "./header.css"
import { useEffect, useState } from "react"
import CreateStudent from "../../pages/Teacher tools/student maker/createStudent";
import { backend } from "../../App";
import NotificationMaker from "../../pages/Teacher tools/add notification/addNotification";

export default function Header() {

    const [headAuth, setHeadAuth] = useState(true);
    const [popOpen, setPopOpen] = useState(false);
    const [nPopOpen, setNPopOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const token = sessionStorage.getItem('token')
    const [refresh, setRefresh] = useState(false);
    const [name, setName] = useState('');
    const [group, setGroup] = useState(-1);
  
    function Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
      (
        async() => {
            if(token){
                const response = await fetch(`${backend}/api/user/${sessionStorage.getItem('user_id')}`, {
                  method: "GET",
                  headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                });
                if (response && response.status === 200) {
                    const data = await response.json();
                    setName(data.name)
                    setGroup(data.group_id)
                }
            }
        }
      )()
    },[token])

    const handleDelete = async(notification_id) => {
        const response = await fetch(`${backend}/api/notifications/${notification_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            setRefresh(true);
        }
    }

    useEffect(() => {
        const fetchNotifications = async () => {
            if(group !== -1){
                const response = await fetch(`${backend}/api/notifications`, {
                  method: 'GET',
                  headers: {
                    'Authorization': 'Bearer ' + token,
                  },
                });
                if (response.status === 200) {
                  const data = await response.json();
                  let arr = [];
                  data.map((not) => {
                    if(not.group_id === group) arr.push(not);
                  })
                  setNotifications(arr);
                } else {
                  console.error(response.status);
                }
            }
        };
        fetchNotifications();
    }, [group, token]);

    useEffect(() => {
        const fetchNotifications = async () => {
            if(group !== -1 && refresh === true){
                const response = await fetch(`${backend}/api/notifications`, {
                  method: 'GET',
                  headers: {
                    'Authorization': 'Bearer ' + token,
                  },
                });
                if (response.status === 200) {
                  const data = await response.json();
                  let arr = [];
                  data.map((not) => {
                    if(not.group_id === group) arr.push(not);
                  })
                  setNotifications(arr);
                } else {
                  console.error(response.status);
                }
                setRefresh(false);
            }
        };
        fetchNotifications();
    }, [refresh]);
  
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };


    const handleLogout = async() =>{
        sessionStorage.clear()
        window.location.reload();
    }

    useEffect(() => {
        (
            async() => {
                if(window.location.pathname === "/login" || window.location.pathname === "/registration") setHeadAuth(true);
                else setHeadAuth(false);
            }
        )()
    },[])

    return (
        <div className="header">
            {popOpen && <CreateStudent setPopOpen={setPopOpen}/>}
            {nPopOpen && <NotificationMaker setPopOpen={setNPopOpen} setRefresh={setRefresh}/>}
            <a href="/" className="header-logo"><img src="assets/images/logo.svg" alt="" /></a>
            { !headAuth ? 
                <>
                    {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <button className="header-addStudent" onClick={() => setPopOpen(true)}>Add new student{sessionStorage.getItem('role') === 'admin' && "/teacher"}</button>}
                    {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <button className="header-addNotification" onClick={() => setNPopOpen(true)}>Create Notification</button>}
                    <img src="assets/icons/header-ring.svg" alt="" className={sessionStorage.getItem("role") !== "student" ? "header-ring" : "header-ring forStudent"} onClick={toggleDropdown}/>
                    {dropdownVisible && (
                        <div className="notification-dropdown">
                        {notifications.length === 0 ? (
                            <p>No notifications</p>
                        ) : (
                            <ul>
                            {notifications.map((notification, index) => (
                                <li key={index}>
                                    <strong>{Capitalize(notification.sender.name)}</strong> posted:
                                    <div className="notification-message">{Capitalize(notification.message)}</div> 
                                    <button onClick={() => handleDelete(notification.notification_id)}>Delete</button>
                                </li>
                            ))}
                            </ul>
                        )}
                        </div>
                    )}
                    <div className="header-profile">
                        <div className="header-username">{name}</div>
                        <div className="header-profile-photo"><img src="assets/icons/header-profile.svg" alt=""/></div>
                        <div className="header-profile-arrow"><img src="assets/icons/red-small-arrow.svg" alt=""/></div>
                        <div className="header-profile-dropdown-content">
                        <a href="/"><div className="header-profile-dropdown-content-text">Site home</div></a>
                            <div className="header-profile-dropdown-content-text" onClick={handleLogout}>Log out</div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="header-login"><a href="/login">Login</a></div>
                </>
            }

        </div>
    )
}