import "./addSatQuestion.css"
import { useState, useRef, useEffect } from "react"
import { backend } from "../../../App";
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, ListsToggle, listsPlugin, Separator, headingsPlugin, imagePlugin, BlockTypeSelect, InsertImage} from '@mdxeditor/editor'
import Loading from "../../../components/Loading/loading";

export default function AddSatQuestion(props) {
    let token = sessionStorage.getItem("token");

    const [explanation, setExplanation] = useState('');
    const [text, setText] = useState(props.mode === 'update' && props.question && props.question.question.question_text ? props.question.question.question_text : '');
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFile2, setUploadFile2] = useState(null);
    const [loading, setLoading] = useState(true);
    const fileUploadRef = useRef();
    const fileUploadRef2 = useRef();

    const handleAvatarUpload = async () => {
        fileUploadRef.current?.click();
    }

    const uploadProfileImage = async () => {
        if (fileUploadRef.current && fileUploadRef.current.files) {
            setUploadFile(fileUploadRef.current.files[0]);
        }
    }

    useEffect(() => {
        (async () => {
            if (uploadFile && fileUploadRef.current && fileUploadRef.current.files) {
                setQuestion({ ...question, image: uploadFile });
            }
        })()
    }, [uploadFile])

    const handleExpImg = async () => {
        fileUploadRef2.current?.click();
    }

    const uploadExpImg = async () => {
        if (fileUploadRef2.current && fileUploadRef2.current.files) {
            setUploadFile2(fileUploadRef2.current.files[0]);
        }
    }

    useEffect(() => {
        (async () => {
            if (uploadFile2 && fileUploadRef2.current && fileUploadRef2.current.files) {
                setQuestion({ ...question, explanation_image: uploadFile2 });
            }
        })()
    }, [uploadFile2])

    useEffect(() => {
        (
            async() => {
                if(props.question){
                    await fetch(`${backend}/api/satQuestions/${props.question.question.sat_question_id}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                if(props.mode === 'update') setExplanation(data.explanation)
                                setLoading(false);
                            });
                        }else {
                            console.log(response.status);
                        }
                    })
                } else {
                    setLoading(false);
                }
            }
        )()
    },[])

    const [question, setQuestion] = useState({
        text: '',
        hint: props.mode === 'update' && props.question && props.question.question.hint ? props.question.question.hint : '',
        image: '',
        explanation_image: '',
        explanation: '',
        section: props.section,
        options: [{ text: '', isCorrect: false }],
    });

    const handleQuestionChange = (e) => {
        const { name, value } = e.target;
        setQuestion({ ...question, [name]: value });
    };

    const handleOptionChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const updatedOptions = question.options.map((option, i) =>
            i === index ? { ...option, [name]: type === 'checkbox' ? checked : value } : option
        );
        setQuestion({ ...question, options: updatedOptions });
    };

    const addOption = () => {
        setQuestion({
            ...question,
            options: [...question.options, { text: '', isCorrect: false }],
        });
    };

    const addQuestionToTest = async () => {
        const formData = new FormData();
        formData.set('test_id', props.sat_id);
        formData.set('question_text', text);
        formData.set('hint', question.hint);
        formData.set('image', question.image);
        formData.set('explanation_image', question.explanation_image);
        formData.set('explanation', explanation);
        formData.set('section', question.section);

        await fetch(`${backend}/api/satQuestions`, {
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + token },
            body: formData
        }).then((response) => {
            if (response && response.status === 201) {
                response.json().then((data) => {
                    const questionId = data.sat_question_id;
                    const answerOptions = question.options.map(opt => ({
                        option_text: opt.text,
                        question_id: questionId,
                        is_correct: opt.isCorrect
                    }));
                    fetch(`${backend}/api/satAnswerOptions/bulk`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                        body: JSON.stringify({
                            answerOptions: answerOptions
                        })
                    }).then((response) => {
                        if (response && response.status === 201) {
                            // Notify parent component or refresh question list
                            props.setPopOpen(false); 
                            props.setCheckUpdate(true);
                        } else {
                            // Handle error
                        }
                    });
                });
            } else {
                // Handle error
            }
        });
    };

    const handleUpdateQuestion = async() => {
        const formData = new FormData();
        formData.set('test_id', props.sat_id);
        formData.set('question_text', text);
        formData.set('hint', question.hint);
        formData.set('image', question.image);
        formData.set('explanation_image', question.explanation_image);
        formData.set('explanation', explanation);
        formData.set('section', question.section);

        await fetch(`${backend}/api/satQuestions/${props.question.question.sat_question_id}`, {
            method: "PUT",
            headers: { 'Authorization': 'Bearer ' + token },
            body: formData
        }).then((response) => {
            if (response && response.status === 200) {
                response.json().then((data) => {
                    props.setCheckUpdate(true);
                    props.setPopOpen(false);
                    console.log()
                });
            } else {
                console.log(response.status)
            }
        });
    }

    async function imageUploadHandler(image) {
        const formD = new FormData()
        formD.append('topic_id', 2)
        formD.append('title', '')
        formD.append('file', image)
        const response = await fetch(`${backend}/api/contents/file`, {
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + token },
          body: formD
        })
        const json = (await response.json())
        return json.resource;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(props.mode === 'add') addQuestionToTest();
        if(props.mode === 'update') handleUpdateQuestion();
    };

    if (loading) {
        return (
            <div className="addQuestionSat">
                <div className="addQuestionSat-close" onClick={() => props.setPopOpen(false)}></div>
                <div className="addQuestionSat-block">
                    <div className="addQuestionSat-ramka">
                        <div>Loading...</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="addQuestionSat">
            <div className="addQuestionSat-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="addQuestionSat-block">
                <div className="addQuestionSat-ramka">
                    <h2>Add Question to Test</h2>
                    {
                        props.mode === 'add' ? 
                        <>
                            <form onSubmit={handleSubmit} className="addQuestionSat-form">
                                <div className="question-section">
                                    <label>Question Text</label>
                                    <MDXEditor
                                        contentEditableClassName="addQuestion-mdxEditor"
                                        markdown={text}
                                        onChange={setText}
                                        plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                            toolbarPlugin({
                                            toolbarContents: () => (
                                                <>
                                                <UndoRedo />
                                                <BoldItalicUnderlineToggles />
                                                <BlockTypeSelect />
                                                <Separator />
                                                <InsertImage />
                                                <Separator />
                                                <ListsToggle />
                                                </>
                                            )
                                            })
                                        ]}
                                    />
                                    <div>
                                        <label>Hint</label>
                                        <textarea
                                            name="hint"
                                            value={question.hint}
                                            onChange={handleQuestionChange}
                                        />
                                    </div>
                                    <div>
                                        <label>Explanation</label>
                                        <MDXEditor
                                        contentEditableClassName="addQuestion-mdxEditor"
                                        markdown={explanation}
                                        onChange={setExplanation}
                                        plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                            toolbarPlugin({
                                            toolbarContents: () => (
                                                <>
                                                <UndoRedo />
                                                <BoldItalicUnderlineToggles />
                                                <BlockTypeSelect />
                                                <Separator />
                                                <InsertImage />
                                                <Separator />
                                                <ListsToggle />
                                                </>
                                            )
                                            })
                                        ]}
                                        />
                                    </div>
                                    <div>
                                        <label>Section</label>
                                        <select
                                        name="section"
                                        value={question.section}
                                        onChange={handleQuestionChange}
                                        >
                                        <option value="">Select a section</option>
                                        <option value="math">Math</option>
                                        <option value="verbal">Verbal</option>
                                        </select>
                                    </div>
                                    {question.options.map((option, index) => (
                                        <div key={index} className="option-section">
                                            <label>Option Text</label>
                                            <textarea
                                                name="text"
                                                value={option.text}
                                                onChange={(e) => handleOptionChange(e, index)}
                                                required
                                            />
                                            <label>
                                                Correct <input
                                                    type="checkbox"
                                                    name="isCorrect"
                                                    checked={option.isCorrect}
                                                    onChange={(e) => handleOptionChange(e, index)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                    <button type="button" className="addQuestionSat-button" onClick={addOption}>
                                        Add Option
                                    </button>
                                </div>
                                <button type="submit" className="addQuestionSat-button">Add Question</button>
                            </form>
                        </>
                        :
                        <>
                            <form onSubmit={handleSubmit} className="addQuestionSat-form">
                                <div className="question-section">
                                    <label>Question Text</label>
                                    <MDXEditor
                                        contentEditableClassName="addQuestion-mdxEditor"
                                        markdown={text}
                                        onChange={setText}
                                        plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                            toolbarPlugin({
                                            toolbarContents: () => (
                                                <>
                                                <UndoRedo />
                                                <BoldItalicUnderlineToggles />
                                                <BlockTypeSelect />
                                                <Separator />
                                                <InsertImage />
                                                <Separator />
                                                <ListsToggle />
                                                </>
                                            )
                                            })
                                        ]}
                                    />
                                    <div>
                                        <label>Hint</label>
                                        <textarea
                                            name="hint"
                                            value={question.hint}
                                            onChange={handleQuestionChange}
                                        />
                                    </div>
                                    <div>
                                        <label>Explanation</label>
                                        <MDXEditor
                                        contentEditableClassName="addQuestion-mdxEditor"
                                        markdown={explanation}
                                        onChange={setExplanation}
                                        plugins={[listsPlugin(), imagePlugin({imageUploadHandler}), headingsPlugin(),
                                            toolbarPlugin({
                                            toolbarContents: () => (
                                                <>
                                                <UndoRedo />
                                                <BoldItalicUnderlineToggles />
                                                <BlockTypeSelect />
                                                <Separator />
                                                <InsertImage />
                                                <Separator />
                                                <ListsToggle />
                                                </>
                                            )
                                            })
                                        ]}
                                        />
                                    </div>
                                    <div>
                                        <label>Section</label>
                                        <select
                                        name="section"
                                        value={question.section}
                                        onChange={handleQuestionChange}
                                        >
                                        <option value="">Select a section</option>
                                        <option value="math">Math</option>
                                        <option value="verbal">Verbal</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="submit" className="addQuestionSat-button">Update Question</button>
                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
