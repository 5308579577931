import "./subjectTabs.css"
import { useEffect, useState } from "react";
import SubTab from "./subTab/subTab";
import SlideIn from "../post-components/slideIn/slideIn"
import { backend } from "../../App";
import { useNavigate } from "react-router-dom";
import TopicMaker from "../../pages/Teacher tools/topicMaker/topicMaker";

export default function SubjectTabs(props) {
    const [openTab, setOpenTab] = useState(props.openAll);
    const [popOpen, setPopOpen] = useState(false);
    const navigate = useNavigate();
    const [topics, setTopics] = useState([
        {
            title: '',
            topicId: -1
        }
    ]);

    useEffect(() => {
        (
            async() => {
                setOpenTab(props.openAll)
            }
        )()
    },[props.openAll])

    useEffect(() => {
        (
            async () => {
                if (props.lessonId !== -1) {
                    const response = await fetch(`${backend}/api/topics/lesson/${props.lessonId}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                    });
                    if (response && response.status === 200) {
                        const data = await response.json();
                        setTopics(prevState => {
                            return data.map(topic => ({
                                title: topic.title,
                                topicId: topic.topic_id,
                            }));
                        });
                    }
                }
            }
        )()
    }, [props.lessonId]);

    const handleLesson = async() => {
        setPopOpen(true);
    }

    const handleDelete = async(topic_id) => {
        const response = await fetch(`${backend}/api/topics/${topic_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            window.location.reload();
        }
    }

    useEffect(() => {
        (
            async() => {
                if(props.closeAll === true)
                {
                    setOpenTab(false);
                    props.setCloseAll(false);
                }
            }
        )()
    },[props.closeAll])

    return (
        <>
            {popOpen && <TopicMaker token={props.token} setPopOpen={setPopOpen} lessonId={props.lessonId}/>}
            <div className="subjectTab-wrapper">
                <div className="subjectTab" onClick={() => setOpenTab(!openTab)}>
                    <div className="subjectTab-trans">
                        <div className="subjectTab-arrow-wrapper"><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subjectTab-arrow-wrapper-image-down" : "subjectTab-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subjectTab-arrow-wrapper-image-down" : "subjectTab-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openTab ? "subjectTab-arrow-wrapper-image-down" : "subjectTab-arrow-wrapper-image-up"} /></div>
                        <div className="subjectTab-image-title">{props.title}</div>
                    </div>
                </div>
            </div>
                {
                    openTab &&
                    <div className="subjectTab-subTabs">
                        <SlideIn startAnimation={openTab} slideStyle="slide-in-tab">
                            {topics[0].topicId !== -1 && topics.map((topic, index) =>
                                <>
                                    <SubTab openAll={props.openAll} title={topic.title} topicId={topic.topicId} index={index} token={props.token} subject={props.subject} setRefresh={props.setRefresh}/>
                                    {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="subjectTab-add" onClick={() => handleDelete(topic.topicId)}>Delete section</div>}
                                </>
                            )}
                            {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <div className="subjectTab-add" onClick={handleLesson}>Add section</div>}
                        </SlideIn>
                    </div>
                }
        </>
    )
}