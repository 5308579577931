import "./satReview.css"
import { useState, useEffect } from "react";
import { backend } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import TestReview from "./test/satTestReview";
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin, setMarkdown$ } from '@mdxeditor/editor'

export default function SatReview(props) {

    const [activeIndex, setActiveIndex] = useState(0);
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [revMark, setRevMark] = useState(false);
    const [questions, setQuestions] = useState();
    const [review, setReview] = useState(true);
    const [userAnswer, setUserAnswer] = useState(false);
    const [correctAns, setCorrectAns] = useState('');
    const [module, setModule] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [editorKey, setEditorKey] = useState(0);

    // Update the key whenever activeIndex changes
    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [activeIndex]);
 
    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 1);
        }, 500);
 
        return () => clearInterval(interval);
    }, [count]);

    const toggleMenu = () => {
        setNavMenuOpen(!navMenuOpen);
    };

    useEffect(() => {
        (
            async() => {
                await fetch(`${backend}/api/satAttempts/${location.state.attempt_id}/user/${sessionStorage.getItem('user_id')}/answers`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                    // credentials: 'include',
                }).then((response) => { 
                    if(response && response.status === 200){
                        response.json().then((data) => {
                            let queArr = [];
                            data.attempt.sat_test.sat_questions.map((question) => {
                                if(question.section === location.state.section){
                                    queArr.push(question);
                                }
                            })
                            setQuestions(queArr.sort((a, b) => a.sat_question_id - b.sat_question_id));
                            handleCorrect(queArr);
                            findCorrectAnswer(queArr);
                        });
                    }
                })
            }
        )()
    },[])


    const handleCorrect = async(questions) => {
        if(questions)
        {
            setUserAnswer(false)
            questions[activeIndex].sat_answer_options.map((answer) => {
                if(answer.is_correct === true && answer.selected === true) setUserAnswer(true);
            })
        }
    }

    const handleSubmit = async(way) => {
        if(location.state.section === 'verbal'){
            navigate('/satReview', {state:{attempt_id: location.state.attempt_id, section: "math"}})
            window.location.reload();
        }
        else if(location.state.section === 'math' && way === -1){
            navigate('/satReview', {state:{attempt_id: location.state.attempt_id, section: "verbal"}})
            window.location.reload();
        }
        else {
            navigate('/demoSAT');
        }
    }

    const findCorrectAnswer = async(questions) => {
        if(questions)
            {
                for(let i = 0; i < questions[activeIndex].sat_answer_options.length; i++) {
                    if(questions[activeIndex].sat_answer_options[i].is_correct === true) {
                        await fetch(`${backend}/api/satAnswerOptions/${questions[activeIndex].sat_answer_options[i].sat_answer_option_id}`, {
                            method: "GET",
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                            // credentials: 'include',
                        }).then((response) => { 
                            if(response && response.status === 200){
                                response.json().then((data) => {
                                    if(correctAns.sat_answer_option_id === data.sat_answer_option_id){
                                        setCorrectAns('');
                                    } else {
                                        setCorrectAns(data)
                                    }
                                });
                            }
                        })
                    }
                }
            }
    }


    const changeQuestion = async (way) => {
        findCorrectAnswer(questions)
        setUserAnswer(false);
        if (questions && questions.length !== 0) {
          if (way === 1 && activeIndex + 1 < questions.length) {
            setActiveIndex(activeIndex + 1);
          }
          if (way === -1 && activeIndex - 1 >= 0) {
            if(module === 2){
                if(location.state.section === 'math' && activeIndex > 22) setActiveIndex(activeIndex - 1);
                if(location.state.section === 'verbal' && activeIndex > 27) setActiveIndex(activeIndex - 1);
            } else {
                setActiveIndex(activeIndex - 1)
            }
          }
        }
      };

      function handleColor(ind) {
        for(let i = 0; i < questions[ind].sat_answer_options.length; i++){
            if(questions[ind].is_marked === true){
                return 'marked';
            } 
            else if(questions[ind].sat_answer_options[i].is_correct === true && questions[ind].sat_answer_options[i].selected === true) return 'correct';
        }
        return 'wrong';
      }


      useEffect(() => {
        (
            async() => {
                if(questions){
                    handleCorrect(questions)
                    findCorrectAnswer(questions)
                }  
            }
        )()
      },[activeIndex])

    const handleModule = async(way) => {
        if(way === -1) {
            setModule(1);
            if(location.state.section === 'math'){
                setActiveIndex(0);
            }
            if(location.state.section === 'verbal'){
                setActiveIndex(0);
            }
        }
        if(way === 1) {
            setModule(2);
            if(location.state.section === 'math'){
                setActiveIndex(22);
            }
            if(location.state.section === 'verbal'){
                setActiveIndex(27);
            }
        }
    }

    return (
        <div className="satReview-page">
            <div className="satReview-task">
                <div className="satReview-content">
                    <div>
                        <div className="satReview-task-question-mark">
                                <div className="satReview-task-question-mark-index">{module === 1 ? activeIndex + 1 : module === 2 && location.state.section === 'math' ? activeIndex - 21 :  activeIndex - 26}</div>
                        </div>
                        {questions && <TestReview token={props.token} question={questions[activeIndex]} activeIndex={activeIndex}/>}
                        <div className={userAnswer === true ? "satReview-task-result-correct" : "satReview-task-result-wrong"}>
                            <div>{userAnswer === true ? "Correct" : "Incorrect"}</div>
                            <div>{userAnswer === true ? "" : `Correct answer: ${correctAns.text ? correctAns.text : ''}`}</div>
                        </div>
                    </div>
                    <div className="satReview-content-explanation">
                        <div className="satContent-explanation-title">Explanation</div>
                        <div className="satContent-explanation-text">
                            { questions && questions[activeIndex] &&
                                <MDXEditor
                                    key={editorKey}
                                    contentEditableClassName="satReview-mdxEditor"
                                    markdown={questions[activeIndex].explanation}
                                    plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
                                    ]}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="satReview-task-nav">
                    {location.state.section === 'math' && <button className="satReview-task-nav-button-r nav-button-text" onClick={() => handleSubmit(-1)}>Previous Section</button>}
                    <button className="satReview-task-nav-button-r nav-button-text" onClick={() => handleSubmit(1)}>{location.state.section === 'verbal' ? "Next section" : "End"}</button>
                    {module === 2 && <button className="satReview-task-nav-button-r nav-button-text" onClick={() => handleModule(-1)}>Previous Module</button>}
                    <div className="question-nav-container">
                        <button className="satReview-task-nav-button-q nav-button-text" onClick={toggleMenu}>Question {module === 1 ? activeIndex + 1 : module === 2 && location.state.section === 'math' ? activeIndex - 21 : activeIndex - 26}/{questions && questions.length / 2}</button>
                        <div className={`question-nav ${navMenuOpen ? 'show' : ''}`}>
                            <ul>
                                {questions?.map((question, index) => {
                                    if(location.state.section === 'math'){
                                        if(module === 1 && index < 22){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : handleColor(index) === 'marked' ? 'question-nav-item-marked' : handleColor(index) === 'correct' ? 'question-nav-item-correct' : 'question-nav-item-wrong'}>
                                                    <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                                </li>
                                            )
                                        } else if(module === 2 && index > 21){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : handleColor(index) === 'marked' ? 'question-nav-item-marked' : handleColor(index) === 'correct' ? 'question-nav-item-correct' : 'question-nav-item-wrong'}>
                                                    <div onClick={() => setActiveIndex(index)}>{index - 21}</div>
                                                </li>
                                            )
                                        }
                                    } else if(location.state.section === 'verbal'){
                                        if(module === 1 && index < 27){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : handleColor(index) === 'marked' ? 'question-nav-item-marked' : handleColor(index) === 'correct' ? 'question-nav-item-correct' : 'question-nav-item-wrong'}>
                                                    <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                                </li>
                                            )
                                        } else if(module === 2 && index > 26){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : handleColor(index) === 'marked' ? 'question-nav-item-marked' : handleColor(index) === 'correct' ? 'question-nav-item-correct' : 'question-nav-item-wrong'}>
                                                    <div onClick={() => setActiveIndex(index)}>{index - 26}</div>
                                                </li>
                                            )
                                        }
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    {questions && activeIndex !== 0 && <button className="satReview-task-nav-button-l nav-button-text" onClick={() => changeQuestion(-1)}>Back</button>}
                    {questions && <>{(module === 1 && location.state.section === 'math' ? activeIndex !== 21 : module === 2 && location.state.section === 'math' ? activeIndex !== 43 : module === 1 && location.state.section === 'verbal' ? activeIndex !== 26 : activeIndex !== 53) && <button className="satReview-task-nav-button-l nav-button-text" onClick={() => changeQuestion(1)}>Next</button>}</>}
                    {module === 1 && <button className="satReview-task-nav-button-l nav-button-text" onClick={() => handleModule(1)}>Next Module</button>}
                </div>
            </div>
        </div>
    )
}