import { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard/dashboard";
import Subject_Header from "../../components/subject_header/subject_header";
import "./verbal.css";
import { backend } from "../../App";
import Grade from "../../components/Grades/grades";
import CourseMain from "../../components/courseMain/courseMain";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/loading";

export default function Verbal(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        (
            async () => {
                if (props.token === null) {
                    if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
                        navigate("/login");
                    }
                }
            }
        )();
    }, [props.token]);

    const [choose, setChoose] = useState('Verbal');
    const [subjectLessons, setSubjectLessons] = useState([
        {
            title: "",
            lessonId: -1,
        }
    ]);

    const GradesData = {
        title: '',
        items: [
            { title: '', grade: '', percentage: '', attempts: '' },
        ]
    };

    useEffect(() => {
        (
            async () => {
                const response = await fetch(`${backend}/api/lessons/group/${sessionStorage.getItem("group_id")}`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                });
                if (response && response.status === 200) {
                    const data = await response.json();
                    let arr = [];
                    data.map((lesson) => {
                        if(lesson.subject === 'verbal'){
                            arr.push({title: lesson.section_title, lessonId: lesson.lesson_id})
                        }
                    })
                    setSubjectLessons(arr);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        )();
    }, []);

    useEffect(() => {
        (
            async () => {
                if(refresh === true) {
                    const response = await fetch(`${backend}/api/lessons/group/${sessionStorage.getItem("group_id")}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                    });
                    if (response && response.status === 200) {
                        const data = await response.json();
                        let arr = [];
                        data.map((lesson) => {
                            if(lesson.subject === 'verbal'){
                                arr.push({title: lesson.section_title, lessonId: lesson.lesson_id})
                            }
                        })
                        setSubjectLessons(arr);
                    } 
                    setRefresh(false);
                }
            }
        )();
    }, [refresh]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="verbal">
            <div className="verbal-dashboard">
                <Dashboard setChoose={setChoose} choose={choose} />
            </div>
            <div className="verbal-content">
                <Subject_Header title="verbal" path={choose} />
                <div className="verbal-content-lessons">
                    {
                        choose === "Verbal" ?
                            <CourseMain subjectLessons={subjectLessons} token={props.token} subject="verbal" setRefresh={setRefresh}/>
                            :
                            choose === "Grades" ?
                                <Grade token={props.token} subject="verbal"/>
                                :
                                <></>
                    }
                </div>
            </div>
        </div>
    );
}
