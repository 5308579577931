import "./addStudentGroup.css"
import { useState, useEffect } from "react";
import { backend } from "../../../App";

export default function AddStudentToGroup(props) {
    
    const [email, setEmail] = useState(props.studentEmail ? props.studentEmail : "");
    const [emailEmpty, setEmailEmpty] = useState(true);
    const [accAdded, setAccAdded] = useState(false);
    const [userId, setUserId] = useState(-1);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');

    const handleKeywordKeyPress = (e) => {
        if( e.key === 'Enter' ) addToGroup(e);
    };

    useEffect(() => {
        (
        async () => {
            setEmail(email.trim());
            setAccAdded(false)
        })();
    }, [email])

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + props.token }
                });
                if (response.ok) {
                    const data = await response.json();
                    if(props.mode === 'move'){
                        let arr = [];
                        data.map((group) => {
                            if(group.group_id !== props.groupFrom) {
                                arr.push(group);
                            }
                        })
                        setGroups(arr);
                        if(arr.length > 0){
                            setSelectedGroup(arr[0].group_id)
                        }
                    }
                    else 
                    {
                        setGroups(data);
                        if (data.length > 0) {
                          setSelectedGroup(data[0].group_id); // Set default selected group
                        }
                    }
                  }
            }
        )()
    }, [backend]);

    const addToGroup = async(e) => {
        e.preventDefault();
        setEmailEmpty(true);
        if(email.length === 0) {
            if(email.length === 0) setEmailEmpty(false);
            return;
        }

        const response = await fetch(`${backend}/api/user`, {
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + props.token }
        });
        if (response.ok) {
            const data = await response.json();
            for(let i = 0; i < data.length; i++){
                if(data[i].email === email){
                    setUserId(data[i].user_id);
                    break;
                }
            }
        }
    }

    useEffect(() => {
        (
            async() => {
                if(userId !== -1)
                {
                    await fetch(`${backend}/api/groups/${selectedGroup}/memberships`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                        body: JSON.stringify({
                            user_id: userId
                        })
                    }).then((response) => { 
                        if (response && response.status === 201)
                        {
                            response.json().then((data) => {
                                if(userId === Number(sessionStorage.getItem('user_id'))){
                                    window.sessionStorage.setItem("group_id", `${data.group_id}`);   
                                }
                                props.refresh(true);
                                props.setPopOpen(false);
                            })
                        }else{
                            console.log(response.status)
                        }
                    })
                }
            }
        )()
    },[userId])

    return(
        <div className="addToGroup-page">
            <div className="addToGroup-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="addToGroup-block">
                <div className="addToGroup-ramka">
                    <div className="addToGroup-content">
                        {
                            props.mode && props.mode === 'move' ?
                            <>
                                <div className="addToGroup-title"> Move student to another group {props.group_name}</div>
                                <div className="addToGroup-email">
                                    <div className={emailEmpty ? "addToGroup-bar-text" : "addToGroup-bar-text addToGroup-empty"}>Email Address</div>
                                    <input type="text" onKeyUp={handleKeywordKeyPress} className={emailEmpty ? "addToGroup-Bar" : "addToGroup-Bar addToGroup-incorrectBar"} value={email} disabled/>
                                </div>
                                <div className="addToGroup-email">
                                    <div className="addToGroup-bar-text">Group</div>
                                    <select 
                                    value={selectedGroup}
                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                    className="addToGroup-Bar"
                                    >
                                    {groups[0] && groups.map(group => (
                                    <option key={group.group_id} value={group.group_id}>
                                        {group.name}
                                    </option>
                                    ))}
                                    </select>
                                </div>
                                <button className="addToGroup-button" onClick={addToGroup}>Move</button>
                                {accAdded && <div className="addToGroup-success">Student was added successfully</div>}
                            </>
                            :
                            <>
                                <div className="addToGroup-title">Add Student to group {props.group_name}</div>
                                <div className="addToGroup-email">
                                    <div className={emailEmpty ? "addToGroup-bar-text" : "addToGroup-bar-text addToGroup-empty"}>Email Address</div>
                                    <input type="text" onKeyUp={handleKeywordKeyPress} className={emailEmpty ? "addToGroup-Bar" : "addToGroup-Bar addToGroup-incorrectBar"} onChange={(e) => setEmail(e.target.value)} value={email}/>
                                </div>
                                <div className="addToGroup-email">
                                    <div className="addToGroup-bar-text">Group</div>
                                    <select 
                                    value={selectedGroup}
                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                    className="addToGroup-Bar"
                                    >
                                    {groups[0] && groups.map(group => (
                                    <option key={group.group_id} value={group.group_id}>
                                        {group.name}
                                    </option>
                                    ))}
                                    </select>
                                </div>
                                <button className="addToGroup-button" onClick={addToGroup}>Add</button>
                                {accAdded && <div className="addToGroup-success">Student was added successfully</div>}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}