import React, { useEffect, useState } from "react";
import { backend } from "../../../App";
import "./studentList.css";
import Loading from "../../../components/Loading/loading";
import AddStudentToGroup from "./addStudentGroup";

export default function StudentList(props) {
    const [loading, setLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const [popOpen, setPopOpen] = useState(false);
    const [student, setStudent] = useState('');
    const [groupId, setGroupId] = useState(-1);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups/${props.groupId}/memberships`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + props.token,
                    },
                });
                if (response && response.status === 200) {
                    const data = await response.json();
                    setStudents(data);
                    setLoading(false);
                }
            }
        )()
    },[])

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups/${props.groupId}/memberships`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + props.token,
                    },
                });
                if (response && response.status === 200) {
                    const data = await response.json();
                    setStudents(data);
                    setRefresh(false);
                }
            }
        )()
    },[refresh])


    const handleAction = async(student, mode) => {
        if(mode === 'delete'){
            const response = await fetch(`${backend}/api/groups/${props.groupId}/memberships/${student.membership_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.token,
                },
            });
            if (response && response.status === 200) {
                setRefresh(true);
            }
        } else {
            const response = await fetch(`${backend}/api/groups/${props.groupId}/memberships/${student.membership_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.token,
                },
            });
            if (response && response.status === 200) {
                setRefresh(true);
            }
            setStudent(student.User.email);
            setGroupId(student.group_id);
            setPopOpen(true);
        }
    }

    if (loading) {
        return (
            <div className="studentList">
                <div className="studentList-close" onClick={() => props.setPopOpen(false)}></div>
                <div className="studentList-block">
                    <div className="studentList-ramka">
                        <div>loading...</div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <>
            {popOpen && <AddStudentToGroup token={props.token} setPopOpen={setPopOpen} refresh={setRefresh} mode='move' studentEmail={student} groupFrom={groupId}/>}
            <div className="studentList">
                <div className="studentList-close" onClick={() => props.setPopOpen(false)}></div>
                <div className="studentList-block">
                    <div className="studentList-ramka">
                        <h1>{props.groupName} Members</h1>
                        {students.length < 1 ? (
                            <p>No members found in this group.</p>
                        ) : (
                            <ul>
                                <li className="studentList-title">
                                    <span>Name</span>
                                    <span>E-mail</span>
                                    <span>Role</span>
                                </li>
                                {students.sort((a, b) => a.User.name.localeCompare(b.User.name)).map((student) => (
                                    <li key={student.User.user_id}>
                                        <span>{student.User.name}</span>
                                        {student.User.role === 'admin' && sessionStorage.getItem('role') === 'student' && <span>{student.User.email}</span>}
                                        {sessionStorage.getItem('role') === 'admin' && <span>{student.User.email}</span>}
                                        <span>{student.User.role}</span>
                                        <button onClick={() => handleAction(student, 'move')}>Move</button>
                                        <button onClick={() => handleAction(student, 'delete')}>Delete</button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
