import React, { useState, useEffect } from 'react';
import './createFile.css'; 
import { backend } from '../../../App';
import { useNavigate } from 'react-router-dom';

const CreateFile = (props) => {
    let token = sessionStorage.getItem("token");
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if(props.contentId !== -1){
            await fetch(`${backend}/api/contents/${props.contentId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((res) => {
                if(res.status === 204){
                }
            })
        }

        const formData = new FormData();
        formData.append('topic_id', 2);
        formData.append('title', 'usefull_tips');
        formData.append('file', file);

        try {
            const contentResponse = await fetch(`${backend}/api/contents/file`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: formData
            });

            if (contentResponse && contentResponse.status === 201) {
                props.setRefresh(true);
                props.setPopOpen(false);
            } else {
                console.error('Failed to upload');
                setError('Failed to upload');
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fileMaker-wrapper">
            <div className="fileMaker-close" onClick={() => props.setPopOpen(false)}></div>
            <div className="fileMaker-block">
                <div className="fileMaker">
                    <h1>Upload Content</h1>
                    <form onSubmit={handleSubmit} className="file-maker-form">
                        <div className="upload-section">
                            <label>
                                File:
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    required
                                />
                            </label>
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Uploading...' : 'Upload'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateFile;
