import "./subject_header.css"
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function Subject_Header(props) {

    

    return(
        <div className="subject-header">
            <div className="subject-header-title">{props.title}</div>
            <div className="subject-header-breadcrumbs">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="#EA392C !important" href="/">Dashboard</Link>
                        <Typography underline="hover" >My courses</Typography>
                        <Link underline="hover" color="#EA392C !important">{props.path}</Link>
                    </Breadcrumbs>
                </div>
            </div>
        </div>
    )
}