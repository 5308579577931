import "./calc.css";
import Wrapper from "./calc-components/wrapper";
import Screen from "./calc-components/screen";
import ButtonBox from "./calc-components/buttonBox";
import Button from "./calc-components/button";
import CalcProvider from "./context/calcContext";
import Draggable from "react-draggable";
import { useState } from "react";

export default function Calc(props) {
  const [degMode, setDegMode] = useState(true); // true for degrees, false for radians

  const toggleDegMode = (mode) => {
    setDegMode(mode);
  };

  const btnValues = [
    ["sin", "cos", "tan", "Deg", "Rad"],
    ["sin-1", "cos-1", "tan-1", "π", "e"],
    ["(", ")", "/"],
    [7, 8, 9, "+"],
    [4, 5, 6, "-"],
    [1, 2, 3, "*"],
    ["AC", 0],
    ["="]
  ];

  return (
    <Draggable>
      <div className="calc">
        <div className="calc-close">
          <button onClick={() => props.setShow(false)}>Close</button>
        </div>
        <CalcProvider>
          <Wrapper>
            <Screen />
            <ButtonBox>
              {btnValues.flat().map((btn, i) => (
                <Button
                  value={btn}
                  key={i}
                  degMode={degMode}
                  setDegMode={toggleDegMode}
                />
              ))}
            </ButtonBox>
          </Wrapper>
        </CalcProvider>
      </div>
    </Draggable>
  );
}
