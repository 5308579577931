import React, { useContext } from 'react';
import { CalcContext } from '../context/calcContext';

const Screen = () => {
  const { calc } = useContext(CalcContext);

  return (
    <div className="screen">
      <div className="expression">{calc.expression}</div>
      <div className="result">{calc.result}</div>
    </div>
  );
};

export default Screen;
