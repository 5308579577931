import React, { useContext } from 'react';
import { CalcContext } from '../context/calcContext';
import { evaluate } from 'mathjs';

const getStyleName = btn => {
  if (typeof btn === 'number' || btn === '.') {
    return 'number';
  }
  if (btn === '=') {
    return 'equals';
  }
  return 'operator';
};

const Button = ({ value, degMode, setDegMode }) => {
  const { calc, setCalc } = useContext(CalcContext);

  const handleBtnClick = () => {
    const handleExpression = (val) => {
      setCalc(prev => ({
        ...prev,
        expression: prev.expression + val
      }));
    };

    const handleEquals = () => {
      try {
        let expression = calc.expression;

        if (degMode) {
          expression = expression.replace(/sin\(([^)]+)\)/g, 'sin($1 * pi / 180)');
          expression = expression.replace(/cos\(([^)]+)\)/g, 'cos($1 * pi / 180)');
          expression = expression.replace(/tan\(([^)]+)\)/g, 'tan($1 * pi / 180)');
          expression = expression.replace(/asin\(([^)]+)\)/g, 'asin($1) * 180 / pi');
          expression = expression.replace(/acos\(([^)]+)\)/g, 'acos($1) * 180 / pi');
          expression = expression.replace(/atan\(([^)]+)\)/g, 'atan($1) * 180 / pi');
        }

        const result = evaluate(expression);
        setCalc({
          expression: result.toString(),
          result: result
        });
      } catch (error) {
        console.error(error);
      }
    };

    const handleClear = () => {
      setCalc({
        expression: '',
        result: 0
      });
    };

    const handleBackspace = () => {
      setCalc(prev => ({
        ...prev,
        expression: prev.expression.slice(0, -1)
      }));
    };

    const actions = {
      "=": handleEquals,
      "AC": handleClear,
      "Back": handleBackspace,
      "Deg": () => setDegMode(true),
      "Rad": () => setDegMode(false),
      "π": () => handleExpression('pi'),
      "e": () => handleExpression('e')
    };

    if (actions[value]) {
      actions[value]();
    } else {
      handleExpression(value.toString());
    }
  };

  const isActive = (value === "Deg" && degMode) || (value === "Rad" && !degMode);
  return (
    <button onClick={handleBtnClick} className={`${getStyleName(value)} button ${isActive ? 'active' : ''}`}>
      {value}
    </button>
  );
};

export default Button;
