import "./addPost.css"
import PostConctructor from "../postConstructor/postConstructor"
import { useState } from "react"


export default function AddPost(props) {

    const [constructorOpen, setConstructorOpen] = useState(false);

    return (
        <>
            <div className="addPost">
                <div>Add post</div>
                <button onClick={() => setConstructorOpen(true)}>Add post</button>
            </div>
            { constructorOpen &&
                    <PostConctructor setConstructorOpen={setConstructorOpen} setRefreshPosts={props.setRefreshPosts} mode="create"/>
            }
        </>
    )
}