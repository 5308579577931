import React, { useState, useEffect } from "react";
import { backend } from "../../App";
import "./group.css";
import CreateGroup from "../../pages/Teacher tools/Group add/groupAdd";
import Dashboard from "../Dashboard/dashboard";
import AddStudentToGroup from "../../pages/Teacher tools/Group add/addStudentGroup";
import Loading from "../Loading/loading";
import StudentList from "../../pages/Teacher tools/Group add/studentList";

export default function GroupMembers(props) {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [groupName, setGroupName] = useState('');
    const [group, setGroup] = useState(-1);
    const [popOpen, setPopOpen] = useState(false);
    const [popOpen2, setPopOpen2] = useState(false);
    const [popOpen3, setPopOpen3] = useState(false);
    const [popOpen4, setPopOpen4] = useState(false);
    let token = sessionStorage.getItem("token");
    const [groups, setGroups] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [groupTitle, setGroupTitle] = useState('');
    const [groupTeacher, setGroupTeacher] = useState('');
    const [groupId, setGroupId] = useState(-1);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        (
            async() => {
                if(sessionStorage.getItem('role') === 'admin'){
                    const response = await fetch(`${backend}/api/groups`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setGroups(data);
                    }
                    const res = await fetch(`${backend}/api/user`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (res.ok) {
                        const data = await res.json();
                        let arr = [];
                        data.map((user) => {
                            if(user.role === 'teacher'){
                                arr.push(user);
                            }
                        })
                        setTeachers(arr);
                    }
                }
            }
        )()
      }, [backend]);

    useEffect(() => {
        (
          async() => {
            const response = await fetch(`${backend}/api/user/${sessionStorage.getItem('user_id')}`, {
              method: "GET",
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            });
            if (response && response.status === 200) {
                const data = await response.json();
                setGroup(data.group_id)
            }
          }
        )()
    },[])    

    useEffect(() => {
        (
          async() => {
            if(refresh === true){
                if(sessionStorage.getItem('role') === 'admin'){
                    const response = await fetch(`${backend}/api/groups`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setGroups(data);
                    }
                    const res = await fetch(`${backend}/api/user`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (res.ok) {
                        const data = await res.json();
                        let arr = [];
                        data.map((user) => {
                            if(user.role === 'teacher'){
                                arr.push(user);
                            }
                        })
                        setTeachers(arr);
                    }
                } else {
                    const response = await fetch(`${backend}/api/user/${sessionStorage.getItem('user_id')}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                      });
                      if (response && response.status === 200) {
                          const data = await response.json();
                          setGroup(data.group_id)
                      }
                }
                setRefresh(false);
            }
          }
        )()
    },[refresh])    

    useEffect(() => {
        const fetchMembers = async () => {
            if(group !== -1){
                try {
                    const response = await fetch(`${backend}/api/groups/${group}/memberships`, {
                        method: "GET",
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setMembers(data);
                    } else {
                        setError("Failed to fetch members.");
                        console.log(response.status)
                    }
                } catch (error) {
                    setError("An error occurred while fetching members.");
                } finally {
                    setLoading(false);
                }
            }
        };

        const fetchName = async () => {
            if(group !== -1){
                const response = await fetch(`${backend}/api/groups/${group}`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroupName(data.name)
                } else {
                    setError("Failed to fetch members.");
                }
            }
        };

        fetchName();
        fetchMembers();
    }, [group]);

    const HandleChange = async(group, mode) => {
        if(mode === 'edit'){
            setGroupTitle(group.name);
            setGroupTeacher(group.teacher_id);
            setGroupId(group.group_id);
            setPopOpen3(true);
        }
        if(mode === 'list'){
            setGroupId(group.group_id);
            setGroupTitle(group.name);
            setPopOpen4(true);
        }
        if(mode === 'delete'){
            await fetch(`${backend}/api/groups/${group.group_id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((res) => {
                if(res.status === 200){
                    setRefresh(true);
                }
            })
        }
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div className='groupMembers'>
            {popOpen && <CreateGroup token={token} setPopOpen={setPopOpen} refresh={setRefresh}/>}
            {popOpen2 && <AddStudentToGroup token={token} setPopOpen={setPopOpen2} refresh={setRefresh}/>}
            {popOpen3 && <CreateGroup token={token} mode='change' setPopOpen={setPopOpen3} groupId={groupId} groupTitle={groupTitle} groupTeacher={groupTeacher} refresh={setRefresh}/>}
            {popOpen4 && <StudentList token={token} setPopOpen={setPopOpen4} groupId={groupId} groupName={groupTitle} refresh={setRefresh}/>}
            <div className="groupMembers-dashboard">
                <Dashboard />
            </div>
            <div className='groupMembers-wrapper'>
                <div className="group-members">
                    {sessionStorage.getItem('role') === 'admin' && <button className="groupMembers-create" onClick={() => setPopOpen(true)}>Create New Group</button>}
                    {sessionStorage.getItem('role') !== 'student' && <button className="groupMembers-addStudent" onClick={() => setPopOpen2(true)}>Add student in this Group</button>}
                    {
                        sessionStorage.getItem('role') === 'admin' ?
                        <>
                            <h1>All groups</h1>
                            <h4>Your current group: " {groupName} "</h4>
                            {groups.length === 0 ? (
                                <p>No groups found.</p>
                            ) : (
                                <ul>
                                    <li className="groups-title">
                                            <span>Group Name</span>
                                            <span>Teacher</span>
                                    </li>
                                    {groups.sort((a, b) => a.group_id - b.group_id).map((group) => (
                                        <>
                                            <li key={group.group_id}>
                                                <span>{group.name}</span>
                                                <span>{teachers.find((user) => user.user_id === group.teacher_id) && teachers.find((user) => user.user_id === group.teacher_id).name}</span>
                                                <button onClick={() => HandleChange(group, 'edit')} className="groups-edit-button">Edit</button>
                                                <button onClick={() => HandleChange(group, 'list')} className="groups-list-button">Student list</button>
                                                <button onClick={() => HandleChange(group, 'delete')} className="groups-delete-button">Delete</button>
                                            </li>
                                        </>
                                    ))}
                                </ul>
                            )}
                        </>
                        :
                        <>
                            <h1>{groupName} Members</h1>
                            {members.length === 0 ? (
                                <p>No members found in this group.</p>
                            ) : (
                                <ul>
                                    <li className="groups-title">
                                        <span>Name</span>
                                        <span>Role</span>
                                        <span>E-mail</span>
                                    </li>
                                    {members.sort((a, b) => (a.User.role !== 'admin' || a.User.role !== 'teacher') && a.User.name.localeCompare(b.User.name)).map((member) => (
                                        <li key={member.User.user_id}>
                                            <span>{member.User.name}</span>
                                            <span>{member.User.role}</span>
                                            {member.User.role !== 'student' && sessionStorage.getItem('role') === 'student' && <span>{member.User.email}</span>}
                                            {sessionStorage.getItem('role') === 'admin' && <span>{member.User.email}</span>}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
