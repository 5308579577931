import "./contentMaker.css";
import React, { useEffect, useState } from "react";
import { backend } from "../../../App";
import QuizMaker from "../quizMaker/quizMaker";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ContentMaker() {

    const [title, setTitle] = useState('');
    const [mode, setMode] = useState('file');
    const modes = ['quiz', 'file', 'video'];
    const [resource, setResource] = useState(-1);
    const [success, setSuccess] = useState();
    const token = sessionStorage.getItem("token");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const createContent = async () => {
        if(mode === 'quiz' && resource !== -1) {
            await fetch(`${backend}/api/contents`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    topic_id: location.state.topicId,
                    title: title,
                    mode: 'text',
                    resource: resource
                })
            }).then((response) => {
                if (response && response.status === 201) {
                    response.json().then((data) => {
                        navigate('/math');
                        window.location.reload();
                    });
                } else {
                    setSuccess(false);
                }
            });
        }
        if(mode !== 'quiz') {
            setLoading(true);
            setError(null);
    
            const formData = new FormData();
            formData.append('topic_id', location.state.topicId);
            formData.append('title', title);
            formData.append('file', file);
    
            try {
                const contentResponse = await fetch(`${backend}/api/contents/${mode}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: formData
                });
    
                if (contentResponse && contentResponse.status === 201) {
                    navigate('/math');
                    window.location.reload();
                } else {
                    console.error('Failed to upload');
                    setError('Failed to upload');
                }
            } catch (err) {
                console.error(err);
                setError('An error occurred');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await createContent();
    };

    return (
        <div className="contentMaker-page">
            <div className="contentMaker-title">Add Content</div>
            <form onSubmit={handleSubmit} className="content-maker-form">
                <div>
                    <label>Mode:</label>
                    <select
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                        required
                    >
                        {modes.map((mode, index) => (
                            <option key={index} value={mode}>
                                {mode}
                            </option>
                        ))}
                    </select>
                    <label>Content Title:</label>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    {mode !== 'quiz' ? (
                        <div className="upload-section">
                            <label>
                                File:
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    required
                                />
                            </label>
                        </div>
                    ) : (
                        <div>
                            { resource === -1 &&
                                <QuizMaker token={token} setResource={setResource} subject={location.state.subject} title={title}/>
                            }
                        </div>
                    )
                    }
                </div>
                {mode === 'quiz' && resource !== -1 && <button type="submit">Create Content</button>}
                {mode !== 'quiz' && <button type="submit" disabled={loading}>
                    {loading ? 'Uploading...' : 'Upload'}
                </button>
                }
            </form>
            {success === false ? <div className="contentMaker-fail">Failed to create content</div> : <></>}
        </div>
    );
}
