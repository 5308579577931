import { useState, useEffect } from "react";
import "./dashboard.css"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import CreateFile from "../../pages/Teacher tools/file maker/createFile";
import { backend } from "../../App";

export default function Dashboard(props) {
    let token = sessionStorage.getItem("token");

    const navigate = useNavigate();
    const location = useLocation();
    const [popOpen, setPopOpen] = useState(false);
    const [dashType, setDashType] = useState('/');
    const [content, setContent] = useState('');
    const [contentId, setContentId] = useState(-1);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        (
            async() => {
                setDashType(location.pathname)
            }
        )()
    },[location.pathname])

    useEffect(() => {
        (
            async() => {
                await fetch(`${backend}/api/contents/topic/2`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                    // credentials: 'include',
                }).then((response) => { 
                    if(response && response.status === 200){
                        response.json().then((data) => {
                            let ind = data.sort((a,b) => a.content_id - b.content_id).reverse().findIndex((file) => file.title === 'usefull_tips')
                            setContent(data[ind].resource);
                            setContentId(data[ind].content_id);
                        });
                    }else {
                        console.log(response.status);
                    }
                })
            }
        )()
    },[])

    useEffect(() => {
        (
            async() => {
                if(refresh === true){
                    await fetch(`${backend}/api/contents/topic/2`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                let ind = data.sort((a,b) => a.content_id - b.content_id).reverse().findIndex((file) => file.title === 'usefull_tips')
                                setContent(data[ind].resource);
                                setContentId(data[ind].content_id);
                            });
                        }else {
                            console.log(response.status);
                        }
                    })
                    setRefresh(false);
                }
            }
        )()
    },[refresh])


    return(
        <>
        {popOpen && <CreateFile setPopOpen={setPopOpen} contentId={contentId} setRefresh={setRefresh}/>}
        {
            (dashType === '/math' || dashType === '/verbal' || dashType === '/demoSAT') &&
            <div className="dashboard dashUp">
                {
                    dashType === '/math' ? 
                        <>
                            <div className={props.choose === 'Math' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Math')}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Math Course</span></div>
                            <div className={props.choose === 'Grades' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Grades')}><img src="assets/icons/grades.svg" alt="" className="dashboard-icons"/><span>Grades</span></div>
                        </>
                    :
                    dashType === '/verbal' ?
                        <>
                            <div className={props.choose === 'Verbal' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Verbal')}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Verbal Course</span></div>
                            <div className={props.choose === 'Grades' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Grades')}><img src="assets/icons/grades.svg" alt="" className="dashboard-icons"/><span>Grades</span></div>
                        </>
                    :
                    dashType === '/demoSAT' ? 
                    <>
                        <div className={props.choose === 'Sat' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Sat')}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Demo SAT</span></div>
                        <div className={props.choose === 'Grades' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => props.setChoose('Grades')}><img src="assets/icons/grades.svg" alt="" className="dashboard-icons"/><span>Grades</span></div>
                    </>
                    :
                    <></>
                }
            </div>
        }
            <div className={(dashType === '/math' || dashType === '/verbal' || dashType === '/demoSAT') ? "dashboard dashDown" : "dashboard"}>
                {(dashType !== '/math' && dashType !== '/verbal' && dashType !== '/demoSAT') && <div className="dashboard-section dashboard-active" onClick={() => navigate("/")}><img src="assets/icons/dashboard.png" alt="" className="dashboard-icons"/><span>{dashType === '/demoSAT' ? "Demo SAT" : 'Dashboard'}</span></div>}
                <div className={ dashType === '/calendar' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => navigate("/calendar")}><img src="assets/icons/calendar.png" alt="" className="dashboard-icons"/><span>Calendar</span></div>
                <div className="dashboard-section"><a href={content !== '' ? content : ''} download={"Useful tips"} target="_blank"><img src="assets/icons/files.png" alt="" className="dashboard-icons"/><span>Useful Tips</span></a> {sessionStorage.getItem('role') !== 'student' && <button className="useful-button" onClick={() => setPopOpen(true)}>Upload</button>} </div>
                <div className={ dashType === '/group' ? "dashboard-section dashboard-active" : "dashboard-section"} onClick={() => navigate('/group')}><img src="assets/icons/group.png" alt="" className="dashboard-icons"/><span>Group</span></div>
                {dashType !== '/math' && <div className="dashboard-section" onClick={() => navigate("/math")}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Math</span></div>}
                {dashType !== '/verbal' && <div className="dashboard-section" onClick={() => navigate("/verbal")}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Verbal</span></div>}
                {dashType !== '/demoSAT' && <div className="dashboard-section" onClick={() => navigate("/demoSAT")}><img src="assets/icons/lesson.png" alt="" className="dashboard-icons"/><span>Demo SAT</span></div>}
            </div>
        </>
    )
}