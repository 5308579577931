import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './Calendar.css';
import Dashboard from '../../components/Dashboard/dashboard';
import { backend } from '../../App';
import CustomEventList from './CustomEventList';
import AddEvent from '../Teacher tools/eventMaker/addEvent';
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, quotePlugin, thematicBreakPlugin, toolbarPlugin, ListsToggle, listsPlugin, Separator, headingsPlugin, linkPlugin} from '@mdxeditor/editor'
import Loading from '../../components/Loading/loading';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const ECalendar = (props) => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '', description: '' });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [loading, setLoading] = useState(true);


  const handleSelectSlot = useCallback(({ start, end }) => {
    setNewEvent({ ...newEvent, start, end });
    setShowModal(true);
  }, [newEvent]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${backend}/api/calendars/my`, {
          method: "GET",
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });

        if (response && response.status === 200) {
          const data = await response.json();
          const fetchedEvents = data.map(ev => ({
            title: ev.title,
            start: new Date(ev.startTime),
            end: new Date(ev.endTime),
            description: ev.description,
            id: ev.schedule_id,
            group_id: ev.group_id,
          }));
          let arr = [];
          setEvents(fetchedEvents);
          setLoading(false);
        } else {
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [props.token]);


  const handleSaveEvent = () => {
    if (newEvent.title && newEvent.start && newEvent.end && newEvent.description) {
      setEvents([...events, { ...newEvent, id: events.length }]);
      setShowModal(false);
      setNewEvent({ title: '', start: '', end: '', description: '' });
    } else {
    }
  };

  const handleEventResize = ({ event, start, end }) => {
    if (event.group_id) {
      return;
    }

    const nextEvents = events.map(existingEvent =>
      existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent
    );
    setEvents(nextEvents);
  };

  const handleEventDrop = ({ event, start, end }) => {
    if (event.group_id) {
      return;
    }

    const nextEvents = events.map(existingEvent =>
      existingEvent.id === event.id ? { ...existingEvent, start, end } : existingEvent
    );
    setEvents(nextEvents);
  };

  const handleSelectEvent = event => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const handleDeleteEvent = async() => {
    const response = await fetch(`${backend}/api/calendars/${selectedEvent.id}`, {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + props.token,
      },
    });
    if (response && response.status === 200) {
    }
    const updatedEvents = events.filter(event => event.id !== selectedEvent.id);
    setEvents(updatedEvents);
    setShowEventModal(false);
  };


  const handleDescriptionChange = value => {
    setNewEvent({ ...newEvent, description: value });
  };

  if (loading) {
    return <Loading />;
  }

  const CustomMonthEvent = ({ event }) => (
    <CustomEventList event={event} />
  );

  const EventComponent = ({ event }) => {
    return (
      <div>
        <strong>{event.title}</strong>
      </div>
    );
  };

  return (
    <div className='calendar'>
      {popOpen && <AddEvent token={props.token} setPopOpen={setPopOpen}/>}
      <div className="calendar-dashboard">
        <Dashboard />
      </div>
      <div className='calendar-wrapper'>
        <DnDCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600, width: 700 }}
          selectable
          onSelectSlot={handleSelectSlot}
          resizable
          onEventResize={handleEventResize}
          onEventDrop={handleEventDrop}
          draggableAccessor={event => !event.group_id}
          onSelectEvent={handleSelectEvent}
          views={[Views.MONTH, Views.DAY]}
          components={{
            event: EventComponent,
            month: {
              event: CustomMonthEvent,
            }
          }}
        />
        {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <button className='calendar-addEvent' onClick={() => setPopOpen(true)}>Add event</button>}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Event Title</Form.Label>
                <Form.Control
                  type="text"
                  value={newEvent.title}
                  onChange={e => setNewEvent({ ...newEvent, title: e.target.value })}
                  placeholder="Enter event title"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <MDXEditor
                  contentEditableClassName="calendar-mdxEditor"
                  markdown={newEvent.description}
                  onChange={handleDescriptionChange}
                  plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), linkPlugin(),
                      toolbarPlugin({
                      toolbarContents: () => (
                          <>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <Separator />
                          <ListsToggle />
                          </>
                      )
                      })
                  ]}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={moment(newEvent.start).format('YYYY-MM-DDTHH:mm')}
                  onChange={e => setNewEvent({ ...newEvent, start: new Date(e.target.value) })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={moment(newEvent.end).format('YYYY-MM-DDTHH:mm')}
                  onChange={e => setNewEvent({ ...newEvent, end: new Date(e.target.value) })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveEvent}>
              Save Event
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEventModal} onHide={() => setShowEventModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Event Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedEvent && (
              <div>
                <p>
                  <strong>Title:</strong> {selectedEvent.title}
                </p>
                <p>
                  <strong>Description:</strong>{' '}
                  <MDXEditor
                  contentEditableClassName="calendar-event-mdxEditor"
                  markdown={selectedEvent.description}
                  plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), linkPlugin(),
                  ]}
                />
                </p>
                <p>
                  <strong>Start:</strong> {selectedEvent.start.toLocaleString()}
                </p>
                <p>
                  <strong>End:</strong> {selectedEvent.end.toLocaleString()}
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {sessionStorage.getItem('role') !== 'student' && (
              <Button variant="danger" onClick={handleDeleteEvent}>
                Delete Event
              </Button>
            )}
            <Button variant="secondary" onClick={() => setShowEventModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ECalendar;
