import "./main.css"
import Dashboard from "../../components/Dashboard/dashboard"
import Lenta from "../../components/Lenta/lenta"
import AddPost from "../../components/post-components/addPost/addPost"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"


export default function Main({token}) {

    const [refreshPosts, setRefreshPosts] = useState(true);

    return (
        <div className="main">
            { token !== null &&
                <>
                    <div className="main-dashboard">
                        <Dashboard />
                        {(sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") && <AddPost setRefreshPosts={setRefreshPosts}/>}
                    </div>
                    <div className="main-lenta">
                        <Lenta setRefreshPosts={setRefreshPosts} refreshPosts={refreshPosts} token={token}/>
                    </div>
                </>
            }
        </div>
    )
}