import { useEffect, useState } from "react";
import "./postCreator.css"
import SlideIn from "../slideIn/slideIn"
import Post from "../post/post"
import reactStringReplace from 'react-string-replace';
import PostConstructor from "../postConstructor/postConstructor";
import { backend } from "../../../App";

const PostCreator = ({backImg, title, content, id, setRefreshPosts}) => {
    const [openPost, setOpenPost] = useState(false);
    const [constructorOpen, setConstructorOpen] = useState(false);
    const contentText = reactStringReplace(content, '\\n', (match, i) => '<br />')
    const token = sessionStorage.getItem('token')

    const handleDelete = async() => {
        const response = await fetch(`${backend}/api/dashboard/announcements/${id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            window.location.reload();
        }
    }


    return (
        <>
        <div className={openPost ? "postCreator-post-open" : "postCreator-post"}>
            {sessionStorage.getItem('role') !== 'student' && <button className="postCreator-delete" onClick={handleDelete}>Delete post</button>}
            {sessionStorage.getItem('role') !== 'student' && <button className="postCreator-delete" onClick={() => setConstructorOpen(true)}>Update post</button>}
            { constructorOpen &&
                    <PostConstructor setRefreshPosts={setRefreshPosts} setConstructorOpen={setConstructorOpen} mode="update" id={id} title={title} description={contentText.join('').replace('"','').replace('"','')} backImg={backImg}/>
            }
            <div className="postCreator-post-image" style={{backgroundImage: `url(${backImg})`}}>
                <div className="postCreator-post-image-trans" > 
                    <div className="postCreator-post-image-title">{title}</div>
                    <div className="postCreator-arrow-wrapper" onClick={() => setOpenPost(!openPost)}><img src="assets/images/lenta-arrow.svg" alt="" className={openPost ? "postCreator-arrow-wrapper-image-down" : "postCreator-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openPost ? "postCreator-arrow-wrapper-image-down" : "postCreator-arrow-wrapper-image-up"} /><img src="assets/images/lenta-arrow.svg" alt="" className={openPost ? "postCreator-arrow-wrapper-image-down" : "postCreator-arrow-wrapper-image-up"} /></div>
                </div>
            </div>
        </div>
        { openPost &&
            <SlideIn startAnimation={openPost} slideStyle="slide-in-post">
                <Post text={contentText.join('').replace('"','').replace('"','')} style={"postStyle"} textStyle={"postTextStyle"}/>
            </SlideIn>
        }
    </>
    )
}

export default PostCreator;
