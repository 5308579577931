import "./sat.css";
import { useState, useEffect } from "react";
import { backend } from "../../App";
import Calc from "../quiz/calculator/calc";
import AddSatQuestion from "../Teacher tools/SAT maker/addSatQuestion";
import { useLocation, useNavigate } from "react-router-dom";
import SATtest from "./sat test/SATtest";
import Loading from "../../components/Loading/loading";

export default function Sat(props) {
    const [questions, setQuestions] = useState();
    const [answers, setAnswers] = useState(JSON.parse(localStorage.getItem('Answers')) || {});
    const [timer, setTimer] = useState();
    const [startTime, setStartTime] = useState(Date.now());
    const [option, setOption] = useState(-1);
    const [activeIndex, setActiveIndex] = useState(0);
    const [hint, setHint] = useState(false);
    const [openCalc, setOpenCalc] = useState(false);
    const [revMark, setRevMark] = useState(false);
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [popOpen, setPopOpen] = useState(true);
    const [popOpenAdd, setPopOpenAdd] = useState(false);    
    const [crossout, setCrossout] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState('');
    const [checkUpdate, setCheckUpdate] = useState(false);
    const [module, setModule] = useState(1);
    const [saveTime, setSaveTime] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setNavMenuOpen(!navMenuOpen);
    };

    useEffect(() => {
        if(location.state === null){
            navigate('/')
        }
        const storedAnswers = JSON.parse(localStorage.getItem('AnswersSat'));
        if (storedAnswers) {
            setAnswers(storedAnswers);
        }
    }, []);

    const changeQuestion = async (way) => {
        let updatedAnswers = { ...answers };
        const questionId = questions[activeIndex].question.sat_question_id;

        if (!updatedAnswers[location.state.sectionType]) {
            updatedAnswers[location.state.sectionType] = [];
        }

        const ind = updatedAnswers[location.state.sectionType].findIndex(question => question.question_id === questionId);
        if (ind !== -1) {
            updatedAnswers[location.state.sectionType][ind].option_id = option;
            updatedAnswers[location.state.sectionType][ind].isMarked = revMark;
        } else {
            updatedAnswers[location.state.sectionType].push({ question_id: questionId, option_id: option, isMarked: revMark });
        }



        localStorage.setItem('AnswersSat', JSON.stringify(updatedAnswers));
        setAnswers(updatedAnswers);

        setOption(-1);
        setRevMark(false);

        if (questions && questions.length !== 0) {
            if (way === 1 && activeIndex + 1 < questions.length) {
                setActiveIndex(activeIndex + 1);
            }
            if (way === -1 && activeIndex - 1 >= 0) {
                if(module === 2){
                    if(location.state.sectionType === 'math' && activeIndex > 22) setActiveIndex(activeIndex - 1);
                    if(location.state.sectionType === 'verbal' && activeIndex > 27) setActiveIndex(activeIndex - 1);
                } else {
                    setActiveIndex(activeIndex - 1)
                }
            }
        }
    };

    const getTime = (duration) => {
        const time = startTime + duration * 60000 - Date.now();
        setSaveTime(time);
        setTimer({ minutes: Math.floor((time / 1000 / 60)), second: Math.floor((time / 1000) % 60) });
        if(time === 0) {
            handleSubmit();
        }
        if(location.state.breakTime === duration && time === 0){
            setPopOpen(false);
            setStartTime(Date.now())
        }
    };

    const newTimer = JSON.parse(localStorage.getItem('time'));

    useEffect(() => {
        if(popOpen === true && location.state && location.state.section === 'last'){
            const interval = setInterval(() => getTime(location.state.breakTime), 1000);
            return () => clearInterval(interval);
        }
        else {

            if(newTimer && newTimer.mode === module && newTimer.section === location.state.sectionType){
                const interval = setInterval(() => getTime(newTimer.saveTime/60000, 1000));
                return () => clearInterval(interval);
            }
            else {
                const interval = setInterval(() => getTime(location.state.sectionType === 'math' ? 35 + Number(location.state.duration) : 37 + Number(location.state.duration)), 1000);
                return () => clearInterval(interval);
            }
        }
    }, [questions, popOpen, module]);


    useEffect(() => {
        if (questions) {
            const storedAnswers = JSON.parse(localStorage.getItem('AnswersSat')) || {};
            const questionId = questions[activeIndex].question.sat_question_id;
            const currentStateAnswers = storedAnswers[location.state.sectionType] || [];
            const ind = currentStateAnswers.findIndex(question => question.question_id === questionId);

            if (ind !== -1) {
                const currentAnswer = currentStateAnswers[ind];
                setOption(currentAnswer.option_id !== undefined ? currentAnswer.option_id : -1);
                setRevMark(currentAnswer.isMarked !== undefined ? currentAnswer.isMarked : false);
            } else {
                setOption(-1);
                setRevMark(false);
            }
        }
    }, [activeIndex, questions, location.state]);

    useEffect(() => {
        (async () => {
            if(location.state !== null){
                await fetch(`${backend}/api/satTests/${location.state.sat_id}/details`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                }).then((response) => {
                    if (response && response.status === 200) {
                        response.json().then((data) => {
                            if(location.state.sectionType === 'verbal'){
                                setQuestions(data.sections.verbal.sort((a, b) => a.question.sat_question_id - b.question.sat_question_id));
                            }
                            if(location.state.sectionType === 'math'){
                                setQuestions(data.sections.math.sort((a, b) => a.question.sat_question_id - b.question.sat_question_id));
                            }
                            setLoading(false);
                        });
                    }
                });
            }
        })();
    }, [location.state]);

    useEffect(() => {
        (
            async () => {
                if(checkUpdate === true){
                    await fetch(`${backend}/api/satTests/${location.state.sat_id}/details`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                    }).then((response) => {
                        if (response && response.status === 200) {
                            response.json().then((data) => {
                                if(location.state.sectionType === 'verbal'){
                                    setQuestions(data.sections.verbal.sort((a, b) => a.question.sat_question_id - b.question.sat_question_id));
                                }
                                if(location.state.sectionType === 'math'){
                                    setQuestions(data.sections.math.sort((a, b) => a.question.sat_question_id - b.question.sat_question_id));
                                }
                            });
                        }
                    });
                    setCheckUpdate(false);
                }
            }
        )();
    }, [checkUpdate]);

    const handleSubmit = async () => {
        // Ensure the current question state is saved before submission
        if (location.state.section === 'last' && sessionStorage.getItem('role') !== 'student') {
            localStorage.removeItem('AnswersSat');
            navigate(`/demoSAT`);
        } else {
            await changeQuestion(0);
    
            const updatedFinalAnswers = { math: [], verbal: [] };
            const storedAnswers = JSON.parse(localStorage.getItem('AnswersSat')) || {};
    
            if (storedAnswers.math) {
                storedAnswers.math.forEach(ans => {
                    updatedFinalAnswers.math.push({ option_id: ans.option_id, question_id: ans.question_id, isMarked: ans.isMarked });
                });
            }
    
            if (storedAnswers.verbal) {
                storedAnswers.verbal.forEach(ans => {
                    updatedFinalAnswers.verbal.push({ option_id: ans.option_id, question_id: ans.question_id, isMarked: ans.isMarked });
                });
            }
    
            if (location.state.section === 'last') {
                if(sessionStorage.getItem('role') === 'student'){
                    await fetch(`${backend}/api/satTests/${location.state.sat_id}/submit`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
                        body: JSON.stringify({ answers: updatedFinalAnswers }),
                    }).then((response) => {
                        if (response && response.status === 200) {
                            localStorage.removeItem('AnswersSat');
                            navigate(`/demoSAT`);
                        }
                    });
                }
                else {
                    localStorage.removeItem('AnswersSat');
                    navigate(`/demoSAT`);
                }
            } else {
                navigate('/SAT-practice', {
                    state: {
                        sectionType: location.state.nextSection,
                        section: 'last',
                        breakTime: location.state.breakTime,
                        duration: location.state.duration2,
                        sat_id: location.state.sat_id
                    }
                });
                window.location.reload();
            }
        }
    };
    
    const deleteQuestion = async(question_id) => {
        const response = await fetch(`${backend}/api/satQuestions/${question_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            setCheckUpdate(true);
        }
    }

    const handleModule = async() => {
        setModule(2);
        setStartTime(Date.now());
        if(location.state.sectionType === 'math'){
            setActiveIndex(22)
        }
        if(location.state.sectionType === 'verbal'){
            setActiveIndex(27)
        }
    }

    const handleUpdateQuestion = async(e) => {
        e.preventDefault();
        if(questions){
            setMode('update');
            setPopOpenAdd(true);
        }
    }

    const handleAddQuestion = async(e) => {
        e.preventDefault();
        setMode('add')
        setPopOpenAdd(true);
    }

    if (loading) {
        return <Loading />;
    }

    const startScreen = (
        <div className="sat-startScreen">
            <div>SAT practice test</div>
            <div>2 Sections: Math and Verbal</div>
            <div>First is {location.state.sectionType}</div>
            <div>{questions && questions.length} questions</div>
            <button onClick={() => setPopOpen(false)}>Start</button>
        </div>
    );

    const breakScreen = (
        <div className="sat-breakScreen">
            <div className="sat-break-first"><strong>TAKE A BREAK:</strong> <span>DO NOT CLOSE YOUR DEVICE!</span></div>
            <div className="sat-break-second">
                <div>You can resume this practice test as soon as you’re ready to move on.  On test day,</div>
                <div>you’ll wait until the clock counts down.</div>
            </div>
            <div>Remaining time </div>
            <div>{timer && timer.minutes !== undefined && timer.second !== undefined ? <div>{timer.minutes}:{timer.second < 10 ? "0" : ''}{timer.second}</div> : <div>Loading</div>}</div>
            <button onClick={() => {
                setStartTime(Date.now());
                setPopOpen(false);
                }}>Resume testing</button>
            <div><strong>REMEMBER TO:</strong>
                <div><strong>1. Stay Hydrated:</strong> Drink water to keep yourself refreshed and focused. </div>
                <div><strong>2. Move Around:</strong> Stretch or take a short walk to keep your blood circulating.</div>
                <div><strong>3. Relax:</strong> Take deep breaths or practice a quick relaxation technique to calm your nerves.</div>
                <div><strong>4. Avoid Overthinking:</strong> Don’t dwell on the previous sections; focus on recharging for the next part.</div>
                <div><strong>5. Snack Wisely:</strong> Have a light, healthy snack if you need an energy boost.</div>
                <div><strong>6. Time Management:</strong> Keep an eye on the clock to ensure you’re ready to resume on time</div>
            </div>
        </div>
    );


    return (
        <div className="sat-page">
            { sessionStorage.getItem("role") === 'student' && popOpen && location.state.section === 'first' && startScreen}
            { sessionStorage.getItem("role") === 'student' && popOpen && location.state.section === 'last' && breakScreen}
            {popOpenAdd && <AddSatQuestion setPopOpen={setPopOpenAdd} sat_id={location.state.sat_id} mode={mode} section={location.state.sectionType} setCheckUpdate={setCheckUpdate} question={questions && questions[activeIndex]}/>}
            <div className="sat-task">
                <div className="sat-task-question-mark">
                      { (sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") ?
                        <>
                            <button className="sat-deleteQuestion" onClick={handleUpdateQuestion}>Update question</button>
                            <div className="sat-subject-title">Subject: {location.state.sectionType}</div>
                        </>
                        :
                        <>
                            <div className="sat-task-question-mark-index">{module === 1 ? activeIndex + 1 : module === 2 && location.state.sectionType === 'math' ? activeIndex - 21 : activeIndex - 26}</div>
                            <img src={`assets/icons/test-mark${String(revMark) === 'true' ? "-g" : ''}.svg`} alt="" onClick={() => setRevMark(!revMark)}/>
                            <div className="sat-task-question-mark-text" onClick={() => setRevMark(!revMark)}>Mark for reveiw</div>
                        </>
                    }
                        
                </div>
                {questions && <SATtest question={questions[activeIndex].question} activeIndex={activeIndex} token={props.token} setOption={setOption} setCheckUpdate={setCheckUpdate} option={option} crossout={crossout} setCrossout={setCrossout}/>}
                <div className="sat-task-nav">
                <button className="sat-task-nav-button-r nav-button-text" onClick={() => 
                {
                    let arr = {
                        saveTime: saveTime,
                        mode: module,
                        section: location.state.sectionType
                    }
                    localStorage.setItem('time', JSON.stringify(arr));
                    changeQuestion(0);
                    navigate('/demoSAT');
                }}>Suspend
                </button>
                <button className="sat-task-nav-button-r nav-button-text" onClick={() => 
                {
                    handleSubmit();
                }}>{location.state.section === 'last' ? "Finish" : "Next Section"}
                </button>
                    <div className="question-nav-container">
                        <button className="sat-task-nav-button-q nav-button-text" onClick={toggleMenu}>Question {module === 1 ? activeIndex + 1 : module === 2 && location.state.sectionType === 'math' ? activeIndex - 21 : activeIndex - 26}/{questions && questions.length / 2}</button>
                        <div className={`question-nav ${navMenuOpen ? 'show' : ''}`}>
                            <ul>
                                {questions?.map((question, index) => {
                                    if(location.state.sectionType === 'math'){
                                        if(module === 1 && index < 22){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                                    <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                                </li>
                                            )
                                        } else if(module === 2 && index > 21){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                                    <div onClick={() => setActiveIndex(index)}>{index - 21}</div>
                                                </li>
                                            )
                                        }
                                    } else if(location.state.sectionType === 'verbal'){
                                        if(module === 1 && index < 27){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                                    <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                                </li>
                                            )
                                        } else if(module === 2 && index > 26){
                                            return (
                                                <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                                    <div onClick={() => setActiveIndex(index)}>{index - 26}</div>
                                                </li>
                                            )
                                        }
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    {questions && activeIndex !== 0 && <button className="sat-task-nav-button-l nav-button-text" onClick={() => changeQuestion(-1)}>Back</button>}
                    {questions && <>{(module === 1 && location.state.sectionType === 'math' ? activeIndex !== 21 : module === 2 && location.state.sectionType === 'math' ? activeIndex !== 43 : module === 1 && location.state.sectionType === 'verbal' ? activeIndex !== 26 : activeIndex !== 53) && <button className="sat-task-nav-button-l nav-button-text" onClick={() => changeQuestion(1)}>Next</button>}</>}
                    {module === 1 && <button className="sat-task-nav-button-l nav-button-text" onClick={handleModule}>Next Module</button>}
                </div>
            </div>
            {(sessionStorage.getItem("role") !== "admin" && sessionStorage.getItem("role") !== "teacher") &&
            <div className="sat-rightBoard">
                {timer && timer.minutes && timer.second ? <div>{timer.minutes}:{timer.second < 10 ? "0" : ''}{timer.second}</div> : <div>Loading</div>}
                <div className="popup" onClick={() => setHint(!hint)}><img src="assets/images/hint.svg" alt="" />Hint
                    <span class={hint ? "popuptext show" : "popuptext"}>{questions && questions[activeIndex] && questions[activeIndex].hint}</span>
                </div>
                {/* <div><img src="assets/images/feedback.svg" alt="" />Feedback</div> */}
                <div onClick={() => setOpenCalc(!openCalc)}><img src="assets/images/calculator.svg" alt="" />Calculator</div>
            </div>
            }
            {openCalc && <Calc setShow={setOpenCalc}/>}
        </div>
    );
}
