import "./satMaker.css";
import { useState, useRef, useEffect } from "react";
import { backend } from "../../../App";
import { useNavigate } from "react-router-dom";

export default function SATMaker() {
    let token = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));
    const navigate = useNavigate();

    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const [satReady, setSatReady] = useState(false);
    const [creating, setCreating] = useState(false);

    const [quiz, setQuiz] = useState({
        name: '',
        deadlines: []
    });

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                      setSelectedGroup(data[0].group_id); // Set default selected group
                    }
                  }
            }
        )()
    }, [backend]);

    const createQuiz = async () => {
        try {
            setCreating(true);
            const response = await fetch(`${backend}/api/satTests`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    name: quiz.name,
                    deadlines: {
                        group_id: selectedGroup,
                        opens: quiz.timeOpen,
                        due: quiz.dueTime,
                    }
                }),
            });
            if (response.status === 201) {
                response.json().then((data) => {
                    for(let i = 0; i < 98; i++){
                        if(i < 44){
                            fetch(`${backend}/api/satQuestions`, {
                                method: "POST",
                                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                                body: JSON.stringify({
                                    test_id: data.sat_test_id,
                                    question_text: '',
                                    hint: '',
                                    image: '',
                                    explanation_image: '',
                                    explanation: '',
                                    section: 'math'
                                }),
                            }).then((response) => {
                                if (response && response.status === 201) {
                                    response.json().then((data) => {
                                        const answerOptions = [];
                                        for(let j = 0; j < 4; j++){
                                            answerOptions.push({
                                                option_text: '',
                                                question_id: data.sat_question_id,
                                                is_correct: ''
                                            });
                                        }
                                        fetch(`${backend}/api/satAnswerOptions/bulk`, {
                                            method: "POST",
                                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                                            body: JSON.stringify({
                                                answerOptions: answerOptions
                                            })
                                        }).then((response) => {
                                            if (response && response.status === 201) {
                                                console.log(response.status)
                                            } else {
                                                console.log(response.status)
                                            }
                                        });
                                    });
                                } else {
                                    // Handle error
                                }
                            });
                        } else {
                            fetch(`${backend}/api/satQuestions`, {
                                method: "POST",
                                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                                body: JSON.stringify({
                                    test_id: data.sat_test_id,
                                    question_text: '',
                                    hint: '',
                                    image: '',
                                    explanation_image: '',
                                    explanation: '',
                                    section: 'verbal'
                                }),
                            }).then((response) => {
                                if (response && response.status === 201) {
                                    response.json().then((data) => {
                                        const answerOptions = [];
                                        for(let j = 0; j < 4; j++){
                                            answerOptions.push({
                                                option_text: '',
                                                question_id: data.sat_question_id,
                                                is_correct: ''
                                            });
                                        }
                                        fetch(`${backend}/api/satAnswerOptions/bulk`, {
                                            method: "POST",
                                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                                            body: JSON.stringify({
                                                answerOptions: answerOptions
                                            })
                                        }).then((response) => {
                                            if (response && response.status === 201) {
                                                console.log(response.status)
                                                if(i === 97) {
                                                    setSatReady(true);
                                                }
                                            } else {
                                                console.log(response.status)
                                            }
                                        });
                                    });
                                } else {
                                    // Handle error
                                }
                            });
                        }
                    }
                })

            }
        } catch (error) {
            console.error("Error creating quiz:", error);
        }
    };

    useEffect(() => {
        (
            async() => {
                if(satReady === true) {
                    await fetch(`${backend}/api/calendars`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                        body: JSON.stringify({
                            title: quiz.name,
                            description: 'SAT Practice',
                            group_id: selectedGroup,
                            startTime: quiz.timeOpen,
                            endTime: quiz.dueTime
                        })
                    }).then((response) => {
                        if (response && response.status === 201) {
                            navigate('/demoSAT')
                            window.location.reload();
                        } else {
                            console.log("Error: " + response.status);
                        }
                    });
                }
            }
        )()
    },[satReady])

    const handleQuizChange = (e) => {
        const { name, value } = e.target;
        setQuiz({ ...quiz, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createQuiz();
    };

    return (
        <div className="quizMaker">
            <h1>Create a SAT Test</h1>
            <form onSubmit={!creating ? handleSubmit : null} className="quiz-maker-form">
                {/* <div>
                    <label>Group</label>
                    <select 
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        className="lessonMaker-group"
                        >
                        {groups[0] && groups.map(group => (
                        <option key={group.group_id} value={group.group_id}>
                            {group.name}
                        </option>
                        ))}
                    </select>
                </div> */}
                <div>
                    <label>Test Name</label>
                    <input
                        name="name"
                        value={quiz.name}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Time Open</label>
                    <input
                        name="timeOpen"
                        type="datetime-local"
                        value={quiz.timeOpen}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Due Time</label>
                    <input
                        name="dueTime"
                        type="datetime-local"
                        value={quiz.dueTime}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <button type="submit" id="createQuizButton">{creating ? 'Creating...' : 'Create SAT Test'}</button>
            </form>
        </div>
    );
}
