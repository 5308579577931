import './App.css';
import Main from './pages/main/main';
import Header from './components/header/header';
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/login/login';
import Reg from './pages/registration/registration';
import Math from './pages/math/math';
import Verbal from './pages/verbal/verbal';
import Quiz from './pages/quiz/quiz';
import ECalendar from './pages/calendar/calendar';
import { useEffect, useState } from 'react';
import DemoSAT from './pages/Demo SAT/demoSAT';
import QuizReview from './pages/quizReview/quizReview';
import CreateStudent from './pages/Teacher tools/student maker/createStudent';
import ContentMaker from './pages/Teacher tools/contentMaker/contentMaker';
import SATMaker from './pages/Teacher tools/SAT maker/satMaker';
import Sat from './pages/SAT practice/sat';
import GroupMembers from './components/group/group';
import SatReview from './pages/satReview/satReview';

function App() {

  const [user, setUser] = useState({user_id: sessionStorage.getItem("user_id"), role: sessionStorage.getItem("role")});

  let token = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));  

  return (
    <div className="App">
        <Header />
        <BrowserRouter>
          <Routes>
                <Route path="/login" element={<Login/>}/>
          </Routes>
          {sessionStorage.getItem('token') ? 
            <Routes>
                  <Route path="/" element={<Main token={token}/>}/>
                  <Route path="/math" element={<Math token={token}/>}/>
                  <Route path="/verbal" element={<Verbal token={token}/>}/>
                  <Route path="/quiz" element={<Quiz token={token}/>} />
                  <Route path="/SAT-practice" element={<Sat token={token}/>} />
                  {user && (user.role === 'admin' || user.role === 'teacher') && <Route path="/create-content" element={<ContentMaker token={token}/>} />}
                  {user && (user.role === 'admin' || user.role === 'teacher') && <Route path="/add-student" element={<CreateStudent token={token}/>} />}
                  {user && (user.role === 'admin' || user.role === 'teacher') && <Route path="/create-SAT-practice" element={<SATMaker/>} />}
                  <Route path="/calendar" element={<ECalendar token={token}/>} />
                  <Route path="/demoSAT" element={<DemoSAT token={token}/>} />
                  <Route path="/quizReview" element={<QuizReview token={token}/>} />
                  <Route path="/satReview" element={<SatReview token={token}/>} />
                  <Route path="/group" element={<GroupMembers />} />
            </Routes>
            :
            <Navigate to="/login" replace={true} />
          }
        </BrowserRouter>
    </div>
  );
}

export const backend = "https://api.sat-practice.kz"

export default App;

