const Datas = [
    [
        200,
        200,
        200,
        220,
        220,
        230,
        240,
        250,
        270,
        270,
        290,
        290,
        300,
        300,
        310,
        330,
        340,
        360,
        370,
        390,
        400,
        420,
        430,
        430,
        450,
        470,
        490,
        490,
        510,
        510,
        530,
        530,
        540,
        570,
        590,
        610,
        630,
        650,
        670,
        690,
        720,
        740,
        760,
        780,
        800
    ],      
    [
        200,
        200,
        200,
        220,
        240,
        260,
        270,
        290,
        300,
        300,
        310,
        320,
        320,
        330,
        340,
        360,
        370,
        390,
        410,
        420,
        440,
        460,
        470,
        490,
        510,
        530,
        540,
        560,
        560,
        560,
        570,
        570,
        570,
        580,
        580,
        580,
        590,
        590,
        600,
        610,
        630,
        650,
        650,
        660,
        670,
        690,
        700,
        710,
        720,
        740,
        750,
        760,
        770,
        790,
        800
    ]
      
]

export default Datas;

  