import "./quizReview.css"
import { useState, useEffect } from "react";
import { backend } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import TestReview from "./test/testReview";
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin } from '@mdxeditor/editor'

export default function QuizReview(props) {

    const [activeIndex, setActiveIndex] = useState(0);
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [revMark, setRevMark] = useState(false);
    const [questions, setQuestions] = useState();
    const [review, setReview] = useState(true);
    const [userAnswer, setUserAnswer] = useState(false);
    const [correctAns, setCorrectAns] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [editorKey, setEditorKey] = useState(0);

    // Update the key whenever activeIndex changes
    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [activeIndex]);
 
    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 1);
        }, 200);
 
        return () => clearInterval(interval);
    }, [count]);

    const toggleMenu = () => {
        setNavMenuOpen(!navMenuOpen);
    };

    useEffect(() => {
        (
            async() => {
                await fetch(`${backend}/api/attempts/${sessionStorage.getItem('user_id')}/answers/${location.state.attempt_id}`, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                    // credentials: 'include',
                }).then((response) => { 
                    if(response && response.status === 200){
                        response.json().then((data) => {
                            setQuestions(data.attempt.test.questions);
                            handleCorrect(data.attempt.test.questions);
                            findCorrectAnswer(data.attempt.test.questions);
                        });
                    }
                })
            }
        )()
    },[])

    const handleCorrect = async(questions) => {
        if(questions)
        {
            questions[activeIndex].answerOptions.map((answer) => {
                if(answer.is_correct === true && answer.selected === true) setUserAnswer(true);
            })
        }
    }

    const handleSubmit = async() => {
        if(location.state.subject === 'math') navigate('/math')
        if(location.state.subject === 'verbal') navigate('/verbal')
    }

    const findCorrectAnswer = async(questions) => {
        if(questions)
            {
                questions[activeIndex].answerOptions.map((answer) => {
                    if(answer.is_correct === true) setCorrectAns(answer);
                })
            }
    }

    const changeQuestion = async (way) => {
        findCorrectAnswer(questions)
        setUserAnswer(false);
        if (questions && questions.length !== 0) {
          if (way === 1 && activeIndex + 1 < questions.length) {
            setActiveIndex(activeIndex + 1);
          }
          if (way === -1 && activeIndex - 1 >= 0) {
            setActiveIndex(activeIndex - 1);
          }
        }
      };

      useEffect(() => {
        (
            async() => {
                if(questions){
                    handleCorrect(questions)
                    findCorrectAnswer(questions)
                }  
            }
        )()
      },[activeIndex])


    return (
        <div className="quizReview-page">
            <div className="quizReview-task">
                <div className="quizReview-content">
                    <div>
                        <div className="quizReview-task-question-mark">
                                <div className="quizReview-task-question-mark-index">{activeIndex + 1}</div>
                                <img src={`assets/icons/test-mark${String(revMark) === 'true' ? "-g" : ''}.svg`} alt="" onClick={() => setRevMark(!revMark)}/>
                                <div className="quizReview-task-question-mark-text" onClick={() => setRevMark(!revMark)}>Mark for reveiw</div>
                        </div>
                        {questions && <TestReview question={questions[activeIndex]} activeIndex={activeIndex} mode={review}/>}
                        <div className={userAnswer === true ? "quizReview-task-result-correct" : "quizReview-task-result-wrong"}>
                            <div>{userAnswer === true ? "Correct" : "Incorrect"}</div>
                            <div>{userAnswer === true ? "" : `Correct answer: ${correctAns && correctAns.option_text}`}</div>
                        </div>
                    </div>
                    <div className="quizReview-content-explanation">
                        <div className="content-explanation-title">Explanation</div>
                        <div className="content-explanation-text">
                            { questions && questions[activeIndex] &&
                                <MDXEditor
                                    key={editorKey}
                                    contentEditableClassName="quizReview-mdxEditor"
                                    markdown={questions[activeIndex].explanation}
                                    plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
                                    ]}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="quizReview-task-nav">
                    <button className="quiz-task-nav-button-r nav-button-text" onClick={handleSubmit}>End</button>
                    <div className="question-nav-container">
                        <button className="quizReview-task-nav-button-q nav-button-text" onClick={toggleMenu}>Question {activeIndex+1}/{questions && questions.length}</button>
                        <div className={`question-nav ${navMenuOpen ? 'show' : ''}`}>
                            <ul>
                                {questions?.map((question, index) => (
                                    <li key={index} className={index === activeIndex ? "question-nav-item-active" : ''}>
                                        <div onClick={() => setActiveIndex(index)}>{index + 1}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {questions && activeIndex !== 0 && <button className="quiz-task-nav-button-l nav-button-text" onClick={() => changeQuestion(-1)}>Back</button>}
                    {questions && activeIndex !== questions.length-1 && <button className="quiz-task-nav-button-l nav-button-text" onClick={() => changeQuestion(1)}>Next</button>}
                </div>
            </div>
        </div>
    )
}