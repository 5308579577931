import "./lenta.css"
import { useEffect, useState } from "react"
import PostCreator from "../post-components/postCreator/postCreator";
import { backend } from "../../App";

export default function Lenta(props) {

    const [posts, setPosts] = useState([]);
    const [countdown, setCountdown] = useState({});
    const [sendTime, setSendTime] = useState();
    const [targetDate, setTargetDate] = useState({});

    const handleWatch = async() => {
        await fetch(`${backend}/api/dashboard/countdowns`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
            // credentials: 'include',
            body: JSON.stringify({
                title: "Hello world",
                target_date: sendTime
            })
        }).then((response) => {
            if(response && response.status === 201){
                window.location.reload();
            }
        })
    }

    const handleDateChange = (e) => {
        const value = e.target.value;
        const date = new Date(value);
        const formattedDate = date.toISOString();
        setSendTime(formattedDate);
    };

    useEffect(() => {
        (
            async() => {
                setCountdown({days: "...", hours: "...", minutes: "...", seconds: "..."});
                await fetch(`${backend}/api/dashboard/countdowns`, {
                    method: "GET",
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                    // credentials: 'include',
                }).then((response) => {
                    if(response && response.status === 200){
                        response.json().then((data) => {
                            setTargetDate(data[data.length-1])
                        })
                    }
                })
            }
        )()
    },[])

    const getTime = () => {
        const time = Date.parse(targetDate.target_date) - Date.now();
        if(Date.parse(targetDate.target_date) < Date.now() ) {
            setCountdown(0)
            return;
        }
        setCountdown({days: Math.floor(time / (1000 * 60 * 60 * 24)), hours: Math.floor((time / (1000 * 60 * 60)) % 24), minutes: Math.floor((time / 1000 / 60) % 60), seconds: Math.floor((time / 1000) % 60)});
    }


    useEffect(() => {
        if(targetDate)
        {   
            const interval = setInterval(() => getTime(targetDate.target_date), 1000);
            return () => clearInterval(interval);
        }
    }, [posts]);


    useEffect(() => {
        (
            async() => {
                if(props.refreshPosts === true) {
                    await fetch(`${backend}/api/dashboard/announcements`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                        // credentials: 'include',
                    }).then((response) => { 
                        if(response && response.status === 200){
                            response.json().then((data) => {
                                setPosts(data);
                            });
                        }
                    })
                    props.setRefreshPosts(false);
                }
            }
        )()
    },[props.refreshPosts])


    return(
        <div className="lenta">
            <div className="lenta-countdown">
                { (sessionStorage.getItem("role") === "admin" || sessionStorage.getItem("role") === "teacher") &&
                    <div className="lenta-countown-setter">
                        <input
                            id="dateTimeInput"
                            type="datetime-local"
                            onChange={handleDateChange}
                            required
                        />
                        <button onClick={handleWatch}>start countdown</button>
                    </div>
                }
                <div>Online Countdown:</div>
                <div className="lenta-countdown-time">
                    <table>
                        <thead>
                            <tr>
                                <th>{countdown.days ? countdown.days : countdown.seconds ? "0" : "0"}</th>
                                <th>{countdown.hours ? countdown.hours : countdown.seconds ? "0" : "0"}</th>
                                <th>{countdown.minutes ? countdown.minutes : countdown.seconds ? "0" : "0"}</th>
                                <th>{countdown.seconds ? countdown.seconds : countdown > 0 ? "0" : "0"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Days</td>
                                <td>Hours</td>
                                <td>Minutes</td>
                                <td>Seconds</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            { posts.map((post, index) => (
                <PostCreator key={index} backImg={post.image} title={post.title} content={post.content} id={post.announcement_id} setRefreshPosts={props.setRefreshPosts}/>
            ))}
        </div>
    )
}