import "./post.css"
import '@mdxeditor/editor/style.css'
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin } from '@mdxeditor/editor'

const Post = ({ text, style, textStyle }) => 
<div className={style}>
    <MDXEditor
        contentEditableClassName="post-mdxEditor"
        markdown={text}
        plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
        ]}
    />
    {/* <button>Register</button> */}
</div>;
export default Post;