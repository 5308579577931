import "./testReview.css"
import { useState, useEffect } from "react";
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin } from '@mdxeditor/editor'


const alphabet = ["A", "B", "C", "D", "E","F","G","H","I","J","K","L","M","N","O","P"];

export default function TestReview(props) {
    const [chooseQ, setChooseQ] = useState(-1);
    const [editorKey, setEditorKey] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 1);
        }, 200);
 
        return () => clearInterval(interval);
    }, [count]);

    // Update the key whenever activeIndex changes
    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [props.question]);


    const handleAnswer = async(option) => {
        setChooseQ(option);
        props.setOption(option);
    }

    return(
        <div>
            <div className="testReview-task-question">
                <div className="testReview-task-question-content">
                <div>{props.question &&
                            <MDXEditor
                                key={editorKey}
                                contentEditableClassName="testReview-mdxEditor"
                                markdown={props.question.question_text}
                                plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
                                ]}
                            />
                        }
                </div>
                    {props.question?.answerOptions?.map((option, index) => (
                        <>
                            <div key={index} className={option.selected ? "testReview-option active" : option.is_correct ? "testReview-option correct" : "testReview-option"}><div className={option.is_correct ? "testReview-letter-correct" : "testReview-letter"}>{option.is_correct ? <img src="assets/images/correct.svg" alt="" /> : alphabet[index]}</div><span>{option.option_text}</span></div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}