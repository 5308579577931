import React, { useState, useEffect } from 'react';
import './gradesItem.css';
import SlideIn from '../post-components/slideIn/slideIn';
import { useNavigate } from 'react-router-dom';

const GradeItem = (data) => {

  const [openTab, setOpenTab] = useState(false);
  const navigate = useNavigate();

  const handleView = async(attempt_id) => {
    navigate("/quizReview", {state:{attempt_id: attempt_id, subject: data.subject}})
  }

  function findTheHighest(attempts) {
    let highest = 0;
    attempts.map((att) => {
      if(att.score > highest) highest = att.score;
    })
    return highest;
  }

  return (
    <>
      <div className={data.index && data.index%2 === 0 ? "grade-item" : "grade-item grade-even"} onClick={() => setOpenTab(!openTab)}>
        <div><img src="assets/icons/GradesArrow.svg" alt="" className='grade-item-arrow'/></div>
        <div>{data.title && data.title}</div>
        <div>{data.item && data.item.length > 0 && findTheHighest(data.item)}/{data.item && data.item.length > 0 && data.item[0].question_count}</div>
        <div>{data.item && data.item.length > 0 && Math.round(findTheHighest(data.item)/data.item[0].question_count * 100 * 100)/100}%</div>
        <div>{data.item && data.item.length > 0 && data.item.length}</div>
      </div>
      {
          openTab &&
              <SlideIn startAnimation={openTab} slideStyle="slide-in-tab">
                {data.item && data.item.map((attempt) => 
                  <div className="grade-item-attempts">
                    <div onClick={() => handleView(attempt.attempt_id)}><a>View attempt</a></div>
                    <div>{attempt.score}</div>
                    <div>{Math.round(attempt.score/attempt.question_count * 100 * 100)/100}%</div>
                    {/* {data.item.details && <div>{data.item.details}</div>}
                    {data.item.time && <div>{data.item.time}</div>} */}
                  </div>
                )}
              </SlideIn>
      }
    </>
  );
};

export default GradeItem;
