import './slideIn.css';
const SlideIn = ({ children, startAnimation, slideStyle }) => {
  const transtionProperties = startAnimation
    ? { marginTop: '0px', opacity: 1, display: "block"}
    : {};
  return (
    <div className={slideStyle} style={transtionProperties}>
      {children}
    </div>
  );
};
export default SlideIn;